import userApi from "@/api/user";
import { useEffect, useState, useCallback, useRef } from "react";
import { User, NotificationMessageType, NotificationType } from "@/types";
import { AdminListHeader } from "@/pages/Admin/AdminListHeader";
import { Trash2 } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { handleAdminError } from "@/pages/Admin/utils/handleAdminError";
import { useAppDispatch } from "@/hooks/types";
import { Checkbox } from "@/components/form/Checkbox";
import { AdminSearchBar } from "@/pages/Admin/components/AdminSearchBar";
import { BulkActions } from "@/pages/Admin/components/AdminBulkActions";
import {
    AdminTable,
    AdminTableHeader,
    AdminTableHeaderCell,
    AdminTableBody,
    AdminTableBodyCell,
    AdminTableBodyRow,
} from "@/pages/Admin/components/Table";
import { Pagination } from "@/pages/Admin/components/AdminPagination";
import { LoadingOverlay } from "@/pages/Admin/components/AdminLoadingOverlay";
import { AdminNoResults } from "@/pages/Admin/components/AdminNoResults";
import { getIsAdminRole } from "@/utils/getIsRole";
import { getIsProductionEnv } from "@/utils/env";
import { addNotification } from "@/store/app/slice";
import { AdminPip } from "@/pages/Admin/components/AdminPip";

type SortColumn =
    | "firstName"
    | "lastName"
    | "email"
    | "role"
    | "createdAt"
    | "isEmailVerified";

const columnNames = [
    { key: "id", label: "ID", width: 220 },
    { key: "firstName", label: "First Name", width: 180 },
    { key: "lastName", label: "Last Name", width: 180 },
    { key: "email", label: "Email", width: 240 },
    { key: "role", label: "Role", width: 120 },
    { key: "createdAt", label: "Created", width: 180 },
    { key: "isEmailVerified", label: "Verified?", width: 120 },
    {
        key: "scheduledDeletionDate",
        label: "Deleting?",
        width: 120,
    },
    { key: "actions", label: "Actions", width: 80 },
];

const itemsPerPage = 50;

export const Users = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState<SortColumn>("firstName");
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedItems, setSelectedItems] = useState<string[]>([]);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const hasInitialised = useRef(false);
    const isProduction = getIsProductionEnv();

    const fetchUsers = useCallback(
        async (
            page: number,
            sortCol: SortColumn = sortColumn,
            sortDir: "asc" | "desc" = sortDirection,
            search: string = searchTerm,
        ) => {
            setIsLoading(true);
            try {
                const result = await userApi.getUsersApi({
                    page: page,
                    limit: itemsPerPage,
                    sortBy: `${sortCol}:${sortDir}`,
                    search: search || undefined,
                });
                setUsers(result.results);
                setTotalItems(result.totalResults);
                setCurrentPage(page);
            } catch (error) {
                handleAdminError(
                    dispatch,
                    error,
                    "Failed to fetch users",
                    NotificationMessageType.ADMIN_USER_SAVE,
                );
            } finally {
                setIsLoading(false);
            }
        },
        [dispatch, searchTerm, sortColumn, sortDirection],
    );

    const handleSort = useCallback(
        (column: SortColumn) => {
            const newSortDirection =
                sortColumn === column && sortDirection === "asc"
                    ? "desc"
                    : "asc";
            setSortColumn(column);
            setSortDirection(newSortDirection);
            setCurrentPage(1);
            fetchUsers(1, column, newSortDirection);
        },
        [sortColumn, sortDirection, fetchUsers],
    );

    useEffect(() => {
        if (!hasInitialised.current) {
            hasInitialised.current = true;
            fetchUsers(1);
        }
    }, [fetchUsers]);

    const handleRowClick = (userId: string) => {
        navigate(`${userId}`);
    };

    const handleDelete = useCallback(
        async (e: React.MouseEvent, userId: string) => {
            e.stopPropagation();
            if (isProduction) {
                dispatch(
                    addNotification({
                        title: "Unable to complete action",
                        desc: "You cannot delete users in production via the admin panel. Removing users must be done via the proper process",
                        messageType: NotificationMessageType.ADMIN_USER_DELETE,
                        type: NotificationType.WARNING,
                    }),
                );
                return;
            }
            try {
                await userApi.deleteUserApi(userId);
                setUsers(prev => prev.filter(user => user.id !== userId));
            } catch (err: unknown) {
                handleAdminError(
                    dispatch,
                    err,
                    "Failed to delete user",
                    NotificationMessageType.ADMIN_USER_DELETE,
                );
            }
        },
        [dispatch, isProduction],
    );

    const handleSearchChange = useCallback(
        (term: string) => {
            if (searchTerm === term) return;
            setSearchTerm(term);
            setCurrentPage(1);
            fetchUsers(1, sortColumn, sortDirection, term);
        },
        [searchTerm, fetchUsers, sortColumn, sortDirection],
    );

    const handlePageChange = (page: number) => {
        fetchUsers(page);
    };

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleSelectItem = (userId: string) => {
        setSelectedItems(prev =>
            prev.includes(userId)
                ? prev.filter(id => id !== userId)
                : [...prev, userId],
        );
    };

    const handleSelectAll = () => {
        setSelectedItems(
            selectedItems.length === users.length
                ? []
                : users.map(user => user.id),
        );
    };

    const handleBulkDelete = useCallback(async () => {
        if (selectedItems.length === 0) return;
        if (isProduction) {
            dispatch(
                addNotification({
                    title: "Unable to complete action",
                    desc: "You cannot delete users in production via the admin panel. Removing users must be done via the proper process",
                    messageType: NotificationMessageType.ADMIN_USER_DELETE,
                    type: NotificationType.WARNING,
                }),
            );
            return;
        }
        try {
            await Promise.all(
                selectedItems.map(userId => userApi.deleteUserApi(userId)),
            );
            setSelectedItems([]);
            fetchUsers(currentPage);
        } catch (error) {
            handleAdminError(
                dispatch,
                error,
                "Failed to delete users",
                NotificationMessageType.ADMIN_USER_DELETE,
            );
        }
    }, [selectedItems, isProduction, dispatch, fetchUsers, currentPage]);

    return (
        <div className="h-full rounded-lg bg-white px-6 pt-2">
            <AdminListHeader
                title="Users"
                description="A list of all users including their name, email, role and email verification status."
                buttonText="Add User"
                to="new"
            />
            <div className="py-4">
                <div className="mb-4 flex items-center justify-between">
                    <div className="ml-3 flex items-center space-x-4">
                        <Checkbox
                            id="select-all"
                            label={`${selectedItems.length} selected`}
                            checked={selectedItems.length === users.length}
                            onChange={handleSelectAll}
                            className="w-36"
                        />
                        <BulkActions
                            selectedCount={selectedItems.length}
                            onDelete={handleBulkDelete}
                        />
                    </div>
                    <div className="flex items-center">
                        <span className="text-sm text-gray-400">
                            {totalItems} users
                        </span>
                    </div>
                    <div className="h-9 w-96">
                        <AdminSearchBar
                            setSearchTerm={handleSearchChange}
                            placeholder="Search by first/last name or email"
                        />
                    </div>
                </div>
                <div className="relative overflow-x-auto">
                    <LoadingOverlay isLoading={isLoading} />
                    <AdminTable>
                        <AdminTableHeader>
                            <th className="w-8 px-6 py-3 text-left text-xs tracking-wider text-gray-500"></th>
                            {columnNames.map(({ key, label, width }) => (
                                <AdminTableHeaderCell
                                    key={key}
                                    label={label}
                                    sortKey={key}
                                    currentSortColumn={sortColumn}
                                    currentSortDirection={sortDirection}
                                    onSort={key =>
                                        handleSort(key as SortColumn)
                                    }
                                    isSortable={
                                        key !== "actions" && key !== "id"
                                    }
                                    width={width}
                                />
                            ))}
                        </AdminTableHeader>
                        <AdminTableBody>
                            {users.map(user => (
                                <AdminTableBodyRow
                                    key={user.id}
                                    onClick={() => handleRowClick(user.id)}
                                >
                                    <AdminTableBodyCell
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Checkbox
                                            checked={selectedItems.includes(
                                                user.id,
                                            )}
                                            onChange={() =>
                                                handleSelectItem(user.id)
                                            }
                                            id={user.id}
                                            label=""
                                            borderless
                                            className="h-5 w-5"
                                        />
                                    </AdminTableBodyCell>
                                    {columnNames.map(({ key, width }) => (
                                        <AdminTableBodyCell
                                            key={key}
                                            width={width}
                                        >
                                            {key === "id" && user.id}
                                            {key === "firstName" &&
                                                user.firstName}
                                            {key === "lastName" &&
                                                user.lastName}
                                            {key === "email" && user.email}
                                            {key === "createdAt" &&
                                                new Date(
                                                    user.createdAt,
                                                ).toLocaleString("en-GB", {
                                                    day: "2-digit",
                                                    month: "2-digit",
                                                    year: "numeric",
                                                    hour: "2-digit",
                                                    minute: "2-digit",
                                                })}
                                            {key === "role" && (
                                                <AdminPip
                                                    colour={
                                                        getIsAdminRole(
                                                            user.role,
                                                        )
                                                            ? "bg-error-100"
                                                            : "bg-primary-100"
                                                    }
                                                >
                                                    {user.role}
                                                </AdminPip>
                                            )}
                                            {key === "isEmailVerified" && (
                                                <AdminPip
                                                    colour={
                                                        user.isEmailVerified
                                                            ? "bg-success-300"
                                                            : "bg-error-300"
                                                    }
                                                >
                                                    {user.isEmailVerified
                                                        ? "Yes"
                                                        : "No"}
                                                </AdminPip>
                                            )}
                                            {key ===
                                                "scheduledDeletionDate" && (
                                                <AdminPip
                                                    colour={
                                                        user.scheduledDeletionDate
                                                            ? "bg-error-300"
                                                            : "bg-neutral-200"
                                                    }
                                                    blank={
                                                        !user.scheduledDeletionDate
                                                    }
                                                >
                                                    {user.scheduledDeletionDate
                                                        ? "Yes"
                                                        : "-"}
                                                </AdminPip>
                                            )}
                                            {key === "actions" && (
                                                <div className="flex gap-4">
                                                    <button
                                                        onClick={e =>
                                                            handleDelete(
                                                                e,
                                                                user.id,
                                                            )
                                                        }
                                                        className="text-error-600 hover:text-error-900"
                                                    >
                                                        <Trash2 className="h-5 w-5" />
                                                    </button>
                                                </div>
                                            )}
                                        </AdminTableBodyCell>
                                    ))}
                                </AdminTableBodyRow>
                            ))}
                        </AdminTableBody>
                    </AdminTable>
                    {users.length === 0 && !isLoading && <AdminNoResults />}
                </div>
                <div className="mt-4 flex justify-end">
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
};
