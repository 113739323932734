import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { Notifications } from "./components/Notifications/Notifications";
import "./index.css";
import { store } from "./store/store";
import CookieConsent from "@/components/CookieConsent/CookieConsent";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";
import { TrackingManager } from "@/components/Tracking/TrackingManager";
import { DeviceProvider } from "@/context/DeviceProvider";
import { detectUserCountry } from "@/i18n";
import { router } from "@/routes";

const rootElement = document.getElementById("root");

if (rootElement === null)
    throw new Error("Root container missing in index.html");

const root = createRoot(rootElement);

detectUserCountry();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60 * 5, // Data is considered fresh for 5 minutes
            gcTime: 1000 * 60 * 30, // Cache is kept for 30 minutes
        },
    },
});

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <DeviceProvider>
                    <ErrorBoundary>
                        <RouterProvider router={router} />
                        <Notifications />
                    </ErrorBoundary>
                    <CookieConsent />
                    <TrackingManager />
                </DeviceProvider>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>,
);
