import { SectionAssistant } from "@/components/Sidebar/Assistant/SectionAssistant";
import { SideBarHeader } from "../SideBarHeader";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getActiveResume } from "@/store/resume/selectors";
import { TabGroup, TabList, TabPanels, TabPanel } from "@headlessui/react";
import { ResumeAssistant } from "@/components/Sidebar/Assistant/ResumeAssistant";
import { useCallback } from "react";
import { setAssistantTabState } from "@/store/assistant/slice";
import { getAssistantTabState } from "@/store/assistant/selectors";
import { TabButton } from "@/components/Tabs/TabButton";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent } from "@/utils/tracking";

export const Assistant = () => {
    const activeResume = useAppSelector(getActiveResume);
    const assistantState = useAppSelector(getAssistantTabState);
    const dispatch = useAppDispatch();

    const onTabChange = useCallback(
        (index: number) => {
            dispatch(setAssistantTabState(index));
            if (index === 0) {
                trackClarityEvent(
                    CLARITY_EVENT_NAMES.ASSISTANT_RESUME_TAB_CLICKED,
                );
            } else {
                trackClarityEvent(
                    CLARITY_EVENT_NAMES.ASSISTANT_SECTION_TAB_CLICKED,
                );
            }
        },
        [dispatch],
    );

    if (!activeResume) return null;

    return (
        <div className="flex flex-col overflow-hidden">
            <SideBarHeader title="Assistant" />
            <TabGroup
                className="overflow-hidden pb-10"
                defaultIndex={assistantState}
                selectedIndex={assistantState}
                onChange={onTabChange}
            >
                <TabList className="mx-4 flex gap-3 rounded-lg border-[1px] border-neutral-300 bg-neutral-50 p-1">
                    <TabButton>Resume</TabButton>
                    <TabButton>Section</TabButton>
                </TabList>
                <div className="mx-4 mt-4 h-[1px] rounded-lg bg-neutral-300" />
                <TabPanels className="no-scrollbar mt-3 h-full overflow-x-hidden overflow-y-scroll pb-4">
                    <TabPanel>
                        <ResumeAssistant />
                    </TabPanel>
                    <TabPanel>
                        <SectionAssistant />
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div>
    );
};
