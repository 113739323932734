import { pdfStyles } from "@/components/PdfDocument/styles/pdfStyles";
import { parseStyles } from "@/components/PdfDocument/utils/parseStyles";
import { PROFILE_IMAGE_KEY } from "@/constants/resume";
import { DEFAULT_IMAGE_SRC } from "@/store/resume/constants";
import { ResumeStyles } from "@/types/resume";
import { Image, View } from "@react-pdf/renderer";
import DefaultUser from "@/assets/images/default_user.png";

export interface PdfImageProps {
    node: any;
    documentStyles: ResumeStyles;
}

// Image won't be visible in DEV due to CORS.
// Disable CORs to test in DEV.
export const PdfImage = ({ node, documentStyles }: PdfImageProps) => {
    const src = node.attribs.src;
    const style = node.attribs?.style ? parseStyles(node.attribs.style) : {};
    const imageStyles = pdfStyles.image;
    const profileImageStyles = documentStyles[PROFILE_IMAGE_KEY];

    const borderRadius = profileImageStyles?.borderRadius
        ? parseInt(profileImageStyles.borderRadius)
        : undefined;

    const allImageStyles = {
        ...style,
        ...imageStyles,
        width: "100%",
        height: "100%",
        borderRadius,
    };

    const outerContainerStyles = {
        position: "relative" as const,
        width: style.width || imageStyles.width,
        height: style.height || imageStyles.height,
        backgroundColor: profileImageStyles?.borderColor || "transparent",
        padding: profileImageStyles?.borderWidth || 0,
        borderRadius,
    };

    const innerContainerStyles = {
        width: "100%",
        height: "100%",
        overflow: "hidden" as const,
        borderRadius,
    };

    const isDefaultImage = src === DEFAULT_IMAGE_SRC;
    const imgSrc = isDefaultImage ? DefaultUser : src; // Avoids CORS issues
    
    return (
        <View style={outerContainerStyles}>
            <View style={innerContainerStyles}>
                <Image
                    src={imgSrc}
                    style={allImageStyles}
                />
            </View>
        </View>
    );
};
