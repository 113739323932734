import { Resume, User } from "@/types";
import { apiRequest } from "./request";

export interface StatsRequest {
    startDate: string | null;
    endDate: string | null;
    userId: string;
}

const getAllResumes = (params: StatsRequest) => apiRequest<Resume[]>(`/stats/resumes`, "GET", params);

const getAllUsers = (params: StatsRequest) => apiRequest<User[]>(`/stats/users`, "GET", params);

const getSubscriptionAnalytics = (params: StatsRequest) => apiRequest(`/stats/subscriptions`, "GET", params);

const getAverageResumesPerUser = (params: StatsRequest) => apiRequest(`/stats/resume-creation`, "GET", params);

export default {
    getAllResumes,
    getAllUsers,
    getSubscriptionAnalytics,
    getAverageResumesPerUser,
};
