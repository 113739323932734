import { useState } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    flexRender,
    ColumnDef,
    ColumnResizeMode,
    SortingState,
} from "@tanstack/react-table";

interface StatTableProps<T> {
    columns: ColumnDef<T>[];
    data: T[];
}

export const StatTable = <T extends { count: number; percentage: number }>({
    columns,
    data,
}: StatTableProps<T>) => {
    const [columnResizeMode] = useState<ColumnResizeMode>("onChange");
    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable({
        data,
        columns,
        columnResizeMode,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: {
            sorting,
        },
        defaultColumn: {
            minSize: 50,
            maxSize: 500,
        },
    });

    return (
        <div className="relative max-h-[300px] overflow-auto">
            <table
                className={`w-full text-left text-xs`}
                style={{ width: table.getCenterTotalSize() }}
            >
                <thead className="sticky top-0 select-none bg-gray-50 text-xs uppercase text-gray-700">
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th
                                    key={header.id}
                                    className="relative truncate px-2 py-2"
                                    style={{
                                        width: header.getSize(),
                                        cursor: header.column.getCanSort()
                                            ? "pointer"
                                            : "default",
                                    }}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    <div className="flex items-center gap-1">
                                        {flexRender(
                                            header.column.columnDef.header,
                                            header.getContext(),
                                        )}
                                        {{
                                            asc: " 🔼",
                                            desc: " 🔽",
                                        }[
                                            header.column.getIsSorted() as string
                                        ] ?? null}
                                    </div>
                                    <div
                                        onMouseDown={header.getResizeHandler()}
                                        onTouchStart={header.getResizeHandler()}
                                        className={`absolute right-0 top-0 h-full w-1 cursor-col-resize touch-none
                                            ${header.column.getIsResizing() ? "bg-blue-500" : "hover:bg-gray-300"}`}
                                    />
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr
                            key={row.id}
                            className="border-b bg-white hover:bg-gray-50"
                        >
                            {row.getVisibleCells().map(cell => (
                                <td
                                    key={cell.id}
                                    className="truncate px-2 py-1"
                                    style={{
                                        width: cell.column.getSize(),
                                    }}
                                >
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext(),
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};
