import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import resumeApi from "@/api/resume";
import { UploadImage } from "@/components/Images/UploadImage";
import { PROFILE_IMAGE_KEY } from "@/constants/resume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import { DEFAULT_HEADER_IMAGE_PATH } from "@/store/resume/constants";
import {
    getActiveResume,
    getActiveResumeSections,
    getActiveResumeStyles,
    getDefaultHeaderImage,
} from "@/store/resume/selectors";
import { removeResumeImage, setActiveResume } from "@/store/resume/slice";
import { ResumeStyles, StyleConfig } from "@/types/resume";

interface ImageProps {
    src: string;
    sectionId: string;
    propId: string;
    propType: "header" | "body";
    editable: boolean;
    showPreview: boolean;
    triggerType?: "button" | "iconButton";
    inlineStyles?: string;
    stylesConfig?: ResumeStyles;
}

export const Image = ({
    src,
    sectionId,
    propId,
    propType,
    editable,
    showPreview,
    triggerType,
    inlineStyles,
    stylesConfig,
}: ImageProps) => {
    const activeResumeSections = useAppSelector(getActiveResumeSections);
    const activeResumeStyles = useAppSelector(getActiveResumeStyles);
    const activeResume = useAppSelector(getActiveResume);
    const { updateField } = useUpdateTemplate();
    const { id } = useParams();
    const defaultHeaderImage = useAppSelector(getDefaultHeaderImage);
    const dispatch = useAppDispatch();

    const imageClassName = useMemo(
        () => activeResumeStyles?.[sectionId]?.className,
        [activeResumeStyles, sectionId],
    );

    const handleSaveImage = useCallback(
        async (imageURL: string, imageStyles?: Record<string, any>) => {
            if (!activeResumeSections || !sectionId || !activeResume) return;

            let resumeStyles = { ...activeResumeStyles };

            if (imageStyles) {
                resumeStyles = {
                    ...resumeStyles,
                    [PROFILE_IMAGE_KEY]: {
                        ...resumeStyles[PROFILE_IMAGE_KEY],
                        ...imageStyles,
                    },
                };
            }

            const sectionBeingUpdated = {
                ...activeResumeSections?.[sectionId],
                header: {
                    ...activeResumeSections?.[sectionId].header,
                    image: imageURL,
                },
            };

            // Unhide the image if it is being updated and its currently hidden
            if (
                imageURL &&
                sectionBeingUpdated.hiddenFields.includes("image")
            ) {
                sectionBeingUpdated.hiddenFields =
                    sectionBeingUpdated.hiddenFields.filter(
                        field => field !== "image",
                    );
            }

            const newResume = {
                ...activeResume,
                styles: resumeStyles,
                sections: {
                    ...activeResumeSections,
                    [sectionId]: sectionBeingUpdated,
                },
            };

            dispatch(setActiveResume(newResume));

            await resumeApi.saveResume(activeResume.id, {
                styles: resumeStyles,
                sections: {
                    ...activeResumeSections,
                    [sectionId]: sectionBeingUpdated,
                },
            });
        },
        [
            activeResumeSections,
            sectionId,
            activeResumeStyles,
            activeResume,
            dispatch,
        ],
    );

    const handleRemoveImage = useCallback(
        async (previousImageUrl?: string) => {
            if (previousImageUrl && id) {
                if (previousImageUrl.includes(DEFAULT_HEADER_IMAGE_PATH)) {
                    return;
                }
                resumeApi.deleteResumeImage(id, previousImageUrl).then(() => {
                    dispatch(removeResumeImage(previousImageUrl));
                    return;
                });
            } else if (!activeResumeSections || !sectionId || !src || !id)
                return;
            if (src.includes(DEFAULT_HEADER_IMAGE_PATH)) return; // Do not delete the default image

            if (!previousImageUrl) {
                updateField(
                    defaultHeaderImage ?? "",
                    propId,
                    propType,
                    sectionId,
                );
                dispatch(removeResumeImage(src));
                await resumeApi.deleteResumeImage(id, src);
            }
        },
        [
            id,
            activeResumeSections,
            sectionId,
            src,
            updateField,
            defaultHeaderImage,
            propId,
            propType,
            dispatch,
        ],
    );

    const imageInlineStyles = useMemo(() => {
        const profileImageStyles = stylesConfig?.[PROFILE_IMAGE_KEY] ?? {};

        let inline = inlineStyles ?? "";
        Object.keys(profileImageStyles).forEach(key => {
            inline += `${key}: ${profileImageStyles[key as keyof StyleConfig]};`;
        });
        return inline;
    }, [inlineStyles, stylesConfig]);

    return (
        <UploadImage
            src={src}
            parentClassName={imageClassName ?? ""}
            handleSaveImage={(imageUrl, imageStyles) =>
                handleSaveImage(imageUrl, imageStyles)
            }
            editable={editable}
            showPreview={showPreview}
            handleRemoveImage={handleRemoveImage}
            triggerType={triggerType}
            inlineStyles={imageInlineStyles}
        />
    );
};
