import { SparklesIcon } from "lucide-react";
import { useMemo } from "react";
import { AppText } from "@/components/Text/AppText";
import { useAppSelector } from "@/hooks/types";
import { getUserHasUsedTrial } from "@/store/user/selectors";

export const LaunchPromotion = () => {
    const hasUsedTrial = useAppSelector(getUserHasUsedTrial);
    const trialPeriodDays = import.meta.env.VITE_STRIPE_TRIAL_PERIOD_DAYS;
    const canApplyTrial = useMemo(() => {
        return Number(trialPeriodDays) > 0 && !hasUsedTrial;
    }, [trialPeriodDays, hasUsedTrial]);

    if (!canApplyTrial) {
        return null;
    }

    return (
        <div className="relative flex w-full items-center justify-center gap-4 overflow-hidden rounded-lg bg-primary-700 px-4 py-3 text-white shadow-md">
            <SparklesIcon className="h-6 w-6 animate-pulse" />
            <div className="flex flex-col items-center">
                <AppText variant="contextheading">
                    LAUNCH PROMOTION: {trialPeriodDays} DAY FREE TRIAL
                </AppText>
                <AppText
                    variant="regular"
                    className="text-primary-100"
                >
                    No credit card details required, included on all plans.
                </AppText>
            </div>
        </div>
    );
};
