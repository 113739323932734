import { AxiosError, AxiosResponse } from "axios";
import { client } from '../client';

export const setupAuthInterceptor = () => {
  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      const originalRequest = error.config;

      // @ts-expect-error _retry is our own custom property
      if (originalRequest && error.response?.status === 401 && !originalRequest._retry) {

        // @ts-expect-error _retry is our own custom property
        originalRequest._retry = true;

        try {
          const refreshToken = localStorage.getItem('refreshToken');

          if (!refreshToken || refreshToken === 'null') {
            // Don't hard reload here as it disrupt the error response.
            return Promise.reject(error);
          }

          const { data } = await client.post('/auth/refresh-tokens', { refreshToken });

          localStorage.setItem('accessToken', data.access.token);
          localStorage.setItem('refreshToken', data.refresh.token);

          originalRequest.headers.Authorization = `Bearer ${data.access.token}`;
          return client(originalRequest);
        } catch {
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );
};