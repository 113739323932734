import { pdf } from "@react-pdf/renderer";
import clsx from "clsx";
import { Download, DownloadIcon, LoaderCircle } from "lucide-react";
import { useState, useCallback } from "react";
import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { PdfDocumentViewer } from "@/components/PdfDocument/PdfDocumentViewer";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import { addNotification, setShowPlansModal } from "@/store/app/slice";
import { getPages } from "@/store/pages/selectors";
import {
    getActiveResume,
    getActiveResumeHeaderImage,
    getSectionsByDocumentTypeId,
} from "@/store/resume/selectors";
import { getUserFlags, getUserId } from "@/store/user/selectors";
import { handleShowSurvey } from "@/store/user/utils";
import { NotificationMessageType, NotificationType } from "@/types";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent } from "@/utils/tracking";

interface DownloadButtonProps {
    responsive?: boolean;
}

export const DownloadButton = ({ responsive }: DownloadButtonProps) => {
    const resume = useAppSelector(getActiveResume);
    const activeResumeImageSignedUrl = useAppSelector(
        getActiveResumeHeaderImage,
    );
    const dispatch = useAppDispatch();
    const { getAndUpdateUserPlan } = useStripe();
    const [buttonLoading, setButtonLoading] = useState(false);

    const sectionsConfig = useAppSelector(state =>
        getSectionsByDocumentTypeId(state, resume?.documentType),
    );
    const pages = useAppSelector(getPages);
    const userId = useAppSelector(getUserId);
    const userFlags = useAppSelector(getUserFlags);

    const onDownload = useCallback(async () => {
        if (!resume) return;
        trackClarityEvent(CLARITY_EVENT_NAMES.DOWNLOAD_RESUME_BUTTON_CLICKED);

        setButtonLoading(true);
        try {
            const plan = await getAndUpdateUserPlan();
            if (plan?.isSubscribed) {
                const blob = await pdf(
                    <PdfDocumentViewer
                        resume={resume}
                        sectionsConfig={sectionsConfig}
                        pages={pages}
                        imageSrc={activeResumeImageSignedUrl}
                    />,
                ).toBlob();
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.download = `${resume.name || "resume"}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                trackClarityEvent(CLARITY_EVENT_NAMES.FILE_DOWNLOADED);
                if (userId) {
                    await handleShowSurvey(dispatch, userId, userFlags);
                }
            } else {
                dispatch(setShowPlansModal("download"));
            }
        } catch (error) {
            dispatch(
                addNotification({
                    title: "Something went wrong",
                    desc: "Unable to download document. Please refresh and try again.",
                    messageType: NotificationMessageType.DOWNLOAD_FAILED,
                    type: NotificationType.ERROR,
                }),
            );
        } finally {
            setButtonLoading(false);
        }
    }, [
        resume,
        getAndUpdateUserPlan,
        sectionsConfig,
        pages,
        activeResumeImageSignedUrl,
        dispatch,
        userId,
        userFlags,
    ]);

    return (
        resume && (
            <>
                {responsive && (
                    <IconButton
                        variant={ButtonVariant.OUTLINE}
                        color={ButtonColourStyles.OUTLINE_PRIMARY}
                        onClick={() => onDownload()}
                        className="flex lg:hidden"
                        disabled={buttonLoading}
                    >
                        <Download size={20} />
                    </IconButton>
                )}
                <Button
                    disabled={buttonLoading}
                    variant={ButtonVariant.OUTLINE}
                    color={ButtonColourStyles.OUTLINE_PRIMARY}
                    className={clsx(
                        "min-w-[132px] xl:min-w-[148px]",
                        responsive && "hidden lg:flex",
                    )}
                    leftIcon={
                        buttonLoading ? (
                            <LoaderCircle
                                className="animate-spin"
                                size={20}
                            />
                        ) : (
                            <DownloadIcon size={20} />
                        )
                    }
                    onClick={onDownload}
                >
                    Download
                </Button>
            </>
        )
    );
};
