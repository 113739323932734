import { loadStripe } from "@stripe/stripe-js/pure";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { Alert } from "@/components/Alert/Alert";
import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { PlanFeature } from "@/components/Plans/PlanFeature";
import { AppText } from "@/components/Text/AppText";
import {
    FREE_PLAN_MAX_RESUMES,
    WEEKLY_PLAN_MAX_RESUMES,
} from "@/constants/plans";
import { translate } from "@/helper/translate";
import { useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import {
    getProducts,
    getProductsError,
    getProductsLoading,
} from "@/store/billing/selectors";
import { StripeProduct } from "@/types/billing";
import { CLARITY_EVENT_NAMES, META_EVENT_ACTIONS } from "@/types/tracking";
import { formatCapitalisedTitle } from "@/utils/string";
import { trackClarityEvent, trackMetaEvent } from "@/utils/tracking";

const freePlan: StripeProduct = {
    id: "free",
    name: "Basic",
    description: "Basic plan",
    price: 0,
    currency: "",
    currencySymbol: "",
    interval: "",
    metadata: {},
    features: [
        "Complete app access",
        "Unlimited resume edits",
        "Unlimited previews",
        `Up to ${FREE_PLAN_MAX_RESUMES} resumes`,
    ],
    priceId: "",
};

const overrideWeeklyPlanDetails: Partial<StripeProduct> = {
    name: "3 Day Free Trial",
    price: 0,
};

const getDescription = (
    product: StripeProduct,
    overrideWeeklyPlan: boolean,
) => {
    if (product.id === "free") {
        return "Get started at no cost. Perfect for exploring our tools and features.";
    } else if (product.interval === "month") {
        return `Unlock unlimited access for ongoing resume building and ${translate("customisation")}.`;
    } else if (product.interval === "week") {
        return overrideWeeklyPlan
            ? "Receive 3 days of free full access to build and download your resume today."
            : "Weekly access to build and download your resume hassle-free.";
    }
    return "";
};

export const Plans = ({
    activePlan = "free",
    showFreePlan = false,
    isProfile = false,
    source = "",
    trialPeriodDays,
}: {
    activePlan?: string;
    showFreePlan?: boolean;
    isProfile?: boolean;
    source?: "" | "upgrade" | "download" | "preview";
    trialPeriodDays?: number;
}) => {
    const [loading, setLoading] = useState(false);
    const stripeProducts = useAppSelector(getProducts);
    const loadingProducts = useAppSelector(getProductsLoading);
    const errorProducts = useAppSelector(getProductsError);
    const [error, setError] = useState<string | null>(null);

    const { getStripeProducts, createStripeCheckoutSession } = useStripe();

    const onPlanSelect = useCallback(
        async (priceId: string) => {
            setError(null);
            const stripe = await loadStripe(
                import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
            );
            const redirectUrl = isProfile
                ? `${window.location.origin}${window.location.pathname}?tab=plan`
                : `${window.location.origin}${window.location.pathname}?source=${source}`;
            try {
                setLoading(true);
                const sessionId = await createStripeCheckoutSession(
                    priceId,
                    redirectUrl,
                    trialPeriodDays,
                );

                trackClarityEvent(CLARITY_EVENT_NAMES.INITIATE_CHECKOUT);

                trackMetaEvent(META_EVENT_ACTIONS.INITIATE_CHECKOUT, {
                    content_category: "Plan selection",
                    content_name: "Select plan",
                    num_items: 1,
                    content_type: "product",
                    content_ids: [priceId],
                });

                const res = await stripe?.redirectToCheckout({
                    sessionId,
                });

                if (res?.error) {
                    console.error(
                        "Error initiating Stripe checkout:",
                        res.error,
                    );
                }
            } catch (error) {
                console.error("Error initiating Stripe checkout:", error);
                setError(error as string);
            } finally {
                setLoading(false);
            }
        },
        [isProfile, source, createStripeCheckoutSession, trialPeriodDays],
    );

    useEffect(() => {
        if (stripeProducts === null && !loadingProducts && !errorProducts)
            getStripeProducts();
    }, [getStripeProducts, stripeProducts, loadingProducts, errorProducts]);

    if (errorProducts || error) {
        return (
            <div className="flex h-[400px] items-center justify-center">
                <Alert
                    message="Something went wrong, please reload and try again."
                    type="error"
                />
            </div>
        );
    }

    const validStripeProducts = trialPeriodDays
        ? (stripeProducts?.filter(product => product.interval === "week") ?? [])
        : (stripeProducts ?? []);
    const allPlans = [freePlan, ...(validStripeProducts || [])];

    const gridColsClass = showFreePlan
        ? validStripeProducts.length === 2
            ? "md:grid-cols-3 grid-cols-1"
            : "md:grid-cols-2 grid-cols-1"
        : validStripeProducts.length === 2
          ? "md:grid-cols-2 grid-cols-1"
          : "grid-cols-1";

    return (
        <div
            className={clsx(
                "mb:mt-0 grid gap-0 gap-y-4 md:gap-4",
                loading || loadingProducts ? "grid-cols-1" : gridColsClass,
                "items-end",
            )}
        >
            {loading || loadingProducts ? (
                <div className="flex min-h-[476px] items-center justify-center">
                    <LoadingSpinner />
                </div>
            ) : (
                allPlans &&
                allPlans.map(product => {
                    if (!showFreePlan && product.id === "free") {
                        return null;
                    }
                    const overrideWeeklyPlan = !!(
                        product.interval === "week" && trialPeriodDays
                    );
                    const productDetails = overrideWeeklyPlan
                        ? { ...product, ...overrideWeeklyPlanDetails }
                        : product;
                    const isPopular =
                        productDetails.metadata.popular || overrideWeeklyPlan;
                    const isClickable =
                        productDetails.id !== "free" &&
                        productDetails.id !== activePlan;

                    return (
                        <div
                            key={productDetails.id}
                            className={clsx(
                                "overflow-hidden rounded-xl rounded-b-xl px-2 pb-2",
                                productDetails.id !== "free" &&
                                    isClickable &&
                                    "cursor-pointer",
                                !isProfile &&
                                    "transition-transform duration-300 ease-in-out",
                                isPopular
                                    ? "bg-primary-700"
                                    : "self-end bg-neutral-100",
                            )}
                            onClick={() =>
                                isClickable &&
                                onPlanSelect(productDetails.priceId)
                            }
                        >
                            {isPopular && (
                                <div className="flex h-12 items-center justify-center rounded-t-xl bg-primary-700 group-hover:border-primary-500">
                                    {isPopular && (
                                        <AppText
                                            variant="contextheading"
                                            className="text-neutral-50"
                                        >
                                            {overrideWeeklyPlan
                                                ? "PROMOTION"
                                                : "MOST POPULAR"}
                                        </AppText>
                                    )}
                                </div>
                            )}
                            <div
                                className={clsx(
                                    activePlan === productDetails.id &&
                                        productDetails.id !== "free"
                                        ? "border-primary-700 bg-primary-50"
                                        : "border-neutral-300",
                                    productDetails.id !== "free" &&
                                        "group-hover:border-primary-500",
                                    "col-span-1 overflow-hidden rounded-xl p-4 px-5",
                                    isPopular && "relative shadow-lg",
                                    isPopular
                                        ? "bg-neutral-50"
                                        : "bg-neutral-100",
                                )}
                            >
                                <div
                                    className={clsx(
                                        "mb-2 flex w-fit gap-2 py-1 text-neutral-900",
                                        isProfile
                                            ? "flex-col "
                                            : "flex-row items-end",
                                    )}
                                >
                                    <AppText variant="subheadings">
                                        {overrideWeeklyPlan
                                            ? "3 DAY FREE TRIAL"
                                            : `${productDetails.name.toUpperCase()} PLAN`}
                                    </AppText>
                                    {overrideWeeklyPlan && (
                                        <AppText
                                            variant="footnote"
                                            className="mb-1 text-neutral-700"
                                        >
                                            (No card required)
                                        </AppText>
                                    )}
                                </div>
                                <AppText
                                    variant="regular"
                                    className="text-neutral-900"
                                >
                                    {getDescription(
                                        product,
                                        overrideWeeklyPlan,
                                    )}
                                </AppText>
                                <div className="my-4 flex items-center gap-x-1">
                                    {!trialPeriodDays && (
                                        <AppText
                                            variant="headings"
                                            className="text-neutral-900"
                                        >
                                            {productDetails.price > 0 &&
                                                productDetails.currencySymbol}
                                            {productDetails.price || "Free"}
                                        </AppText>
                                    )}
                                    {productDetails.price > 0 &&
                                        productDetails.interval && (
                                            <AppText
                                                variant="labelsbuttons"
                                                className="leading-5 text-neutral-900"
                                            >
                                                {" "}
                                                (
                                                {formatCapitalisedTitle(
                                                    productDetails.interval,
                                                )}
                                                )
                                            </AppText>
                                        )}
                                </div>
                                <div
                                    className={clsx(
                                        "h-1 w-full border-b-2 border-dashed",
                                        isPopular
                                            ? "border-primary-700"
                                            : "border-neutral-300",
                                    )}
                                />
                                <ul
                                    role="list"
                                    className="mb-6 mt-6 space-y-2"
                                >
                                    {productDetails.id === "free" && (
                                        <>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Complete app access
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Unlimited edits
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Unlimited <b>previews</b>
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Create up to{" "}
                                                    <b>
                                                        {FREE_PLAN_MAX_RESUMES}
                                                    </b>{" "}
                                                    resumes
                                                </AppText>
                                            </PlanFeature>
                                        </>
                                    )}
                                    {productDetails.interval === "month" && (
                                        <>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Complete app access
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Unlimited edits
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Unlimited <b>downloads</b>
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Unlimited resumes
                                                </AppText>
                                            </PlanFeature>
                                        </>
                                    )}
                                    {productDetails.interval === "week" && (
                                        <>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Complete app access
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Unlimited edits
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Unlimited <b>downloads</b>
                                                </AppText>
                                            </PlanFeature>
                                            <PlanFeature>
                                                <AppText
                                                    variant="regular"
                                                    className="text-neutral-700"
                                                >
                                                    Create up to{" "}
                                                    <b>
                                                        {
                                                            WEEKLY_PLAN_MAX_RESUMES
                                                        }
                                                    </b>{" "}
                                                    resumes
                                                </AppText>
                                            </PlanFeature>
                                        </>
                                    )}
                                </ul>
                                {activePlan &&
                                activePlan === productDetails.id ? (
                                    <Button
                                        color={
                                            ButtonColourStyles.SOLID_SOFT_INDIGO
                                        }
                                        className="pointer-events-none w-full cursor-not-allowed"
                                    >
                                        Active
                                    </Button>
                                ) : activePlan && activePlan !== "free" ? (
                                    <Button
                                        color={
                                            ButtonColourStyles.SOLID_SOFT_INDIGO
                                        }
                                        variant={ButtonVariant.SOLID}
                                        className="w-full"
                                        onClick={() =>
                                            onPlanSelect(productDetails.priceId)
                                        }
                                    >
                                        Switch to this Plan
                                    </Button>
                                ) : (
                                    <Button
                                        color={
                                            isPopular
                                                ? ButtonColourStyles.SOLID_PRIMARY
                                                : ButtonColourStyles.SOLID_WHITE
                                        }
                                        className={clsx(
                                            "w-full",
                                            !isPopular &&
                                                "hover:bg-neutral-50/80",
                                        )}
                                        onClick={() =>
                                            onPlanSelect(productDetails.priceId)
                                        }
                                    >
                                        {overrideWeeklyPlan
                                            ? "Start Free Trial"
                                            : "Select Plan"}
                                    </Button>
                                )}
                            </div>
                        </div>
                    );
                })
            )}
        </div>
    );
};
