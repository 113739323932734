import resumeApi from "@/api/resume";
import { PresetOption } from "@/components/Presets/PresetOption";
import {
    DateLocationPresetLeftColumnPreview,
    DateLocationPresetLeftRowPreview,
    DateLocationPresetRightColumnPreview,
    DateLocationPresetRightRowPreview,
} from "@/components/Presets/PresetPreviews";
import {
    DATE_LOCATION_LAYOUT_KEY,
    DATE_LOCATION_POSITION_KEY,
    GLOBAL_STYLE_KEY,
} from "@/constants/resume";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import {
    getActiveResume,
    getActiveResumeGlobalStyles,
} from "@/store/resume/selectors";
import { setActiveResume } from "@/store/resume/slice";
import { StyleConfig } from "@/types/resume";
import { CLARITY_EVENT_NAMES } from "@/types/tracking";
import { trackClarityEvent } from "@/utils/tracking";
import { useEffect, useState } from "react";

const presetOptions = [
    {
        id: "left-column",
        name: "Left List",
        preview: <DateLocationPresetLeftColumnPreview />,
        values: {
            [DATE_LOCATION_LAYOUT_KEY]: "column",
            [DATE_LOCATION_POSITION_KEY]: "column",
        },
        eventName: CLARITY_EVENT_NAMES.PRESET_SECTION_LEFT_COLUMN_USED,
    },
    {
        id: "left-row",
        name: "Left Row",
        preview: <DateLocationPresetLeftRowPreview />,
        values: {
            [DATE_LOCATION_LAYOUT_KEY]: "row",
            [DATE_LOCATION_POSITION_KEY]: "column",
        },
        eventName: CLARITY_EVENT_NAMES.PRESET_SECTION_LEFT_ROW_USED,
    },
    {
        id: "right-column",
        name: "Right List",
        preview: <DateLocationPresetRightColumnPreview />,
        values: {
            [DATE_LOCATION_LAYOUT_KEY]: "column",
            [DATE_LOCATION_POSITION_KEY]: "row",
        },
        eventName: CLARITY_EVENT_NAMES.PRESET_SECTION_RIGHT_COLUMN_USED,
    },
    {
        id: "right-row",
        name: "Right Row",
        preview: <DateLocationPresetRightRowPreview />,
        values: {
            [DATE_LOCATION_LAYOUT_KEY]: "row",
            [DATE_LOCATION_POSITION_KEY]: "row",
        },
        eventName: CLARITY_EVENT_NAMES.PRESET_SECTION_RIGHT_ROW_USED,
    },
];

export const DateLocationPresets = () => {
    const [activePreset, setActivePreset] = useState<string | null>(null);
    const globalStyles = useAppSelector(getActiveResumeGlobalStyles);
    const activeResume = useAppSelector(getActiveResume);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (activePreset === null && globalStyles) {
            const currentPosition = globalStyles[DATE_LOCATION_POSITION_KEY];
            const currentLayout = globalStyles[DATE_LOCATION_LAYOUT_KEY];

            const currentPreset = presetOptions.find(
                preset =>
                    preset.values[DATE_LOCATION_POSITION_KEY] ===
                        currentPosition &&
                    preset.values[DATE_LOCATION_LAYOUT_KEY] === currentLayout,
            );

            if (currentPreset) {
                setActivePreset(currentPreset.id);
            }
        }
    }, [activePreset, globalStyles]);

    const onPresetClick = (id: string) => {
        const preset = presetOptions.find(preset => preset.id === id);
        if (!preset || !activeResume) return;
        setActivePreset(id);
        const newGlobalStyles: StyleConfig = {
            ...activeResume.styles[GLOBAL_STYLE_KEY],
            ...preset.values,
        };
        trackClarityEvent(preset.eventName);

        const allStyles: Record<string, StyleConfig> = {
            ...activeResume.styles,
            [GLOBAL_STYLE_KEY]: newGlobalStyles,
        };

        dispatch(
            setActiveResume({
                ...activeResume,
                styles: allStyles,
            }),
        );

        resumeApi.saveResume(activeResume.id, {
            name: activeResume.name,
            sections: activeResume.sections,
            styles: allStyles,
        });
    };

    return (
        <div className="flex gap-4">
            {presetOptions.map(option => (
                <PresetOption
                    key={option.id}
                    onClick={() => onPresetClick(option.id)}
                    active={activePreset === option.id}
                    {...option}
                />
            ))}
        </div>
    );
};
