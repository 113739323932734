interface StatsContainerProps {
    children: React.ReactNode;
    title: string;
}

export const StatsContainer = ({ children, title }: StatsContainerProps) => {
    return (
        <div className="mb-2 flex flex-col gap-4 rounded-lg bg-white p-4 shadow-md">
            <h2 className="border-b pb-3 text-2xl font-bold text-gray-800">
                {title}
            </h2>
            {children}
        </div>
    );
};
