import userApi from "@/api/user";
import { Modal } from "@/components/Modal/Modal";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { setShowSideBar, setSideBarState } from "@/store/app/slice";
import { getIsModalOpen } from "@/store/modal/selectors";
import { closeModal } from "@/store/modal/slice";
import { getUserFlags, getUserDetails } from "@/store/user/selectors";
import { setUserDetails } from "@/store/user/slice";
import { SideBarState } from "@/types/app";
import { ModalTypes } from "@/types/modal";
import { USER_FLAGS } from "@/types/user";
import { useTour } from "@reactour/tour";
import { useCallback, useEffect } from "react";

const TourModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.TOUR));
    const dispatch = useAppDispatch();
    const { setIsOpen, setCurrentStep } = useTour();
    const userFlags = useAppSelector(getUserFlags);
    const user = useAppSelector(getUserDetails);

    const startTour = useCallback(() => {
        setCurrentStep(0);
        dispatch(setShowSideBar(true));
        dispatch(setSideBarState(SideBarState.STYLES));
        dispatch(closeModal(ModalTypes.TOUR));
        const timer = setTimeout(() => {
            setIsOpen(true);
        }, 500); // need enough time for sidebar to open fully
        return () => clearTimeout(timer);
    }, [dispatch, setCurrentStep, setIsOpen]);

    const updateUserFlags = useCallback(() => {
        userApi
            .saveUserApi(
                {
                    flags: {
                        ...userFlags,
                        [USER_FLAGS.HAS_SEEN_RESUME_BUILDER_TOUR]: true,
                    },
                },
                user?.id,
            )
            .then(user => {
                dispatch(setUserDetails(user));
                setIsOpen(false);
                setCurrentStep(0); // Reset tour step
                dispatch(setShowSideBar(false));
                dispatch(setSideBarState(SideBarState.CLOSED));
            });
    }, [dispatch, setCurrentStep, setIsOpen, user?.id, userFlags]);

    useEffect(() => {
        return () => {
            setCurrentStep(0);
        };
    }, [setCurrentStep]);

    const cancelTour = useCallback(() => {
        dispatch(closeModal(ModalTypes.TOUR));
        updateUserFlags();
    }, [dispatch, updateUserFlags]);

    return (
        <Modal
            modalType={ModalTypes.TOUR}
            open={isOpen}
            onClose={cancelTour}
            width="lg"
            title="1Template Tutorial"
            footer={
                <ModalFooter
                    primaryActionOnClick={startTour}
                    primaryActionText="Start"
                    secondaryActionOnClick={cancelTour}
                    secondaryActionText="Skip"
                />
            }
        >
            <div className="">
                <AppText
                    variant="regular"
                    className="text-neutral-900"
                >
                    If this is your first time using 1Template, our quick and
                    easy tutorial will help you get started and make the most
                    out of our features.
                </AppText>
            </div>
        </Modal>
    );
};

export default TourModal;
