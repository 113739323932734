import { AppText } from "@/components/Text/AppText";
import clsx from "clsx";

interface CheckboxRadioContainerProps {
    id: string;
    label: string | React.ReactNode;
    description?: string;
    className?: string;
    borderless?: boolean;
    children: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    vertical?: boolean;
}

export const CheckboxRadioContainer = ({
    id,
    label,
    description,
    children,
    className,
    borderless,
    onClick,
    vertical,
}: CheckboxRadioContainerProps) => {
    return (
        <>
            <div
                onClick={onClick}
                className={clsx(
                    "relative flex h-9 cursor-pointer items-center",
                    className,
                    !borderless && "rounded-lg ring-1 ring-neutral-300",
                    !!label && "px-3",
                    vertical && "flex-col",
                )}
            >
                <div
                    className={clsx("flex h-6 items-center", !!label && !vertical && "mr-2")}
                >
                    {children}
                </div>

                <label
                    htmlFor={id}
                    className="flex-1 cursor-pointer leading-none"
                >
                    <AppText
                        variant="labelsbuttons"
                        className="select-none text-neutral-900"
                    >
                        {label}
                    </AppText>
                </label>
            </div>
            {description && (
                <AppText
                    variant="regular"
                    className="text-neutral-700"
                >
                    {description}
                </AppText>
            )}
        </>
    );
};
