import { useOutlet } from "react-router-dom";
import ErrorBoundary from "@/components/ErrorBoundary/ErrorBoundary";
import { MyEditorProvider } from "@/context/EditorProvider";

export const AuthLayout = () => {
    const outlet = useOutlet();

    return (
        <ErrorBoundary>
            <MyEditorProvider>{outlet}</MyEditorProvider>
        </ErrorBoundary>
    );
};
