import { AppText } from "@/components/Text/AppText";
import { InfoItem } from "@/components/InfoItem/InfoItem";
import { useAppSelector } from "@/hooks/types";
import {
    getUserActivePlan,
    getUserPlanEndingSoon,
    getUserTrialDaysRemaining,
} from "@/store/user/selectors";
import clsx from "clsx";
import { UserPlan } from "@/types/billing";
import { useMemo } from "react";

const getStatusLabel = (
    userPlan: UserPlan,
    planEndingSoon: boolean,
    trialDaysRemaining: number,
) => {
    if (userPlan?.details?.isCancelling) {
        const planEndDate = userPlan?.details?.endDate
            ? new Date(userPlan.details.endDate).toLocaleDateString()
            : "N/A";
        return `Active until ${planEndDate}`;
    }
    if (trialDaysRemaining) {
        if (trialDaysRemaining === 1) {
            return `Active - Trial Ending Soon`;
        }
        return `Trial Active - ${trialDaysRemaining} days remaining`;
    }
    if (planEndingSoon) {
        return "Active - Plan Ending Soon";
    }
    return "Active";
};

export const PlanSummary = () => {
    const userPlan = useAppSelector(getUserActivePlan);
    const planEndingSoon = useAppSelector(getUserPlanEndingSoon);
    const trialDaysRemaining = useAppSelector(getUserTrialDaysRemaining);
    const planEndDate = userPlan?.details?.endDate
        ? new Date(userPlan.details.endDate).toLocaleDateString()
        : "N/A";

    const statusLabel = getStatusLabel(
        userPlan,
        planEndingSoon,
        trialDaysRemaining,
    );

    const isTrialing = useMemo(
        () => userPlan?.planStatus === "trialing",
        [userPlan],
    );

    if (!userPlan) {
        return null;
    }

    return (
        <div className="mb-6 rounded-lg bg-white p-4 ring-2 ring-neutral-300">
            <div className="mb-2 flex items-center justify-between">
                <div className="flex items-center">
                    <AppText
                        variant="subheadings"
                        className="mr-2 text-neutral-700"
                    >
                        Status:
                    </AppText>
                    <AppText
                        variant="subheadings"
                        className={clsx(
                            userPlan.details?.isCancelling || planEndingSoon
                                ? "text-error-500"
                                : "text-primary-700",
                        )}
                    >
                        {statusLabel}
                    </AppText>
                </div>
            </div>
            <div className="grid w-full grid-cols-1 gap-1 md:w-2/3">
                {userPlan.details?.name && (
                    <InfoItem
                        label="Plan name:"
                        value={
                            isTrialing
                                ? "Free Trial"
                                : userPlan.details?.name || "N/A"
                        }
                    />
                )}
                {!isTrialing &&
                    userPlan.details?.interval &&
                    !userPlan.details?.isCancelling && (
                        <InfoItem
                            label="Billing cycle frequency:"
                            value={userPlan.details?.interval || "N/A"}
                        />
                    )}
                {userPlan.details?.isCancelling ? (
                    <InfoItem
                        label="Subscription ending on:"
                        value={planEndDate ? planEndDate : "N/A"}
                    />
                ) : (
                    !isTrialing && (
                        <InfoItem
                            label="Next billing date:"
                            value={planEndDate ? planEndDate : "N/A"}
                        />
                    )
                )}
                {userPlan.details?.trialEndDate && (
                    <InfoItem
                        label="Trial end date:"
                        value={new Date(
                            userPlan.details.trialEndDate,
                        ).toLocaleString(undefined, {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            formatMatcher: "basic",
                        })}
                    />
                )}
            </div>
        </div>
    );
};
