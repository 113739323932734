import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";

export const getAppState = (state: RootState) => state.app;

export const getNotifications = createSelector(
    getAppState,
    app => app.notifications,
);

export const getSideBarState = createSelector(
    getAppState,
    app => app.sideBar,
);

export const getShowSideBar = createSelector(
    getAppState,
    app => app.showSideBar,
);

export const getSaveTemplateState = createSelector(
    getAppState,
    app => app.saveTemplateState,
);

export const getTemplateLoadingState = createSelector(
    getAppState,
    app => app.templateLoadingState,
);

export const getShowPlansModal = createSelector(
    getAppState,
    app => app.showPlansModal,
);

export const getShowRearrangeModal = createSelector(
    getAppState,
    app => app.showRearrangeModal,
);

export const getDatePickerId = createSelector(
    getAppState,
    app => app.datePickerId,
);

export const getDebugBuilder = createSelector(
    getAppState,
    app => app.debugBuilder,
);

export const getDebugPageMargins = createSelector(
    getAppState,
    app => app.debugPageMargins,
);

export const getDebugSectionSpacing = createSelector(
    getAppState,
    app => app.debugSectionSpacing,
);

export const getShowFeedbackSurvey = createSelector(
    getAppState,
    app => app.showFeedbackSurvey,
);
