import { Resume, User } from "@/types";
import { ColumnLayout } from "@/types/resume";

interface StatItem {
    label: string;
}

export const calculatePreferenceStats = (
    allUsers: User[],
    possibleValues: StatItem[],
    preferenceKey: "industries" | "jobRoles" | "experienceLevel" | "jobTypes",
) => {
    const stats = possibleValues.reduce(
        (acc, item) => ({
            ...acc,
            [item.label]: 0,
        }),
        {} as Record<string, number>
    );

    allUsers.forEach(user => {
        const userPreferences = user.preferences?.[preferenceKey] ?? [];
        if (Array.isArray(userPreferences)) {
            userPreferences.forEach(pref => {
                if (pref in stats) {
                    stats[pref] += 1;
                }
            });
        } else if (typeof userPreferences === "string") {
            if (userPreferences in stats) {
                stats[userPreferences] += 1;
            }
        }
    });

    const totalUsers = allUsers.length;
    return Object.entries(stats)
        .map(([key, count]) => ({
            [preferenceKey]: key,
            count,
            percentage: totalUsers ? (count / totalUsers) * 100 : 0,
        }))
        .sort((a, b) => b.count - a.count);
};

export const calculateResumeLayoutStats = (
    allResumes: Resume[],
) => {
    const validLayouts = Object.keys(ColumnLayout)
        .filter(key => isNaN(Number(key))) as (keyof typeof ColumnLayout)[];

    const stats = validLayouts.reduce(
        (acc, layout) => ({
            ...acc,
            [layout]: 0,
        }),
        {} as Record<string, number>
    );

    allResumes.forEach(resume => {
        let value: keyof typeof ColumnLayout;
        if (resume.columnLayout === 1) {
            value = "SINGLE";
        } else if (resume.columnLayout === 2) {
            value = "DOUBLE";
        } else {
            value = resume.columnLayout as keyof typeof ColumnLayout;
        }

        if (validLayouts.includes(value)) {
            stats[value] += 1;
        }
    });

    const totalResumes = allResumes.length;
    return Object.entries(stats)
        .map(([key, count]) => ({
            layout: key,
            count,
            percentage: totalResumes ? (count / totalResumes) * 100 : 0,
        }))
        .sort((a, b) => b.count - a.count);
};
