import ResetPasswordEmail from "@/email/emails/ResetPasswordEmail";
import PasswordChangedEmail from "@/email/emails/PasswordChangedEmail";
import DeleteRequestConfirmationEmail from "@/email/emails/DeleteRequestConfirmationEmail";
import VerifyEmail from "@/email/emails/VerifyEmail";
import { render } from "@react-email/render";
import emailApi from "@/api/email";
import ErrorReportEmail from "@/email/emails/ErrorReportEmail";
import { User } from "@/types";
import FeedbackEmail from "@/email/emails/FeedbackEmail";
import SurveyEmail from "@/email/emails/SurveyEmail";
import { DeviceType } from "@/types/device";

const sendEmail = async (html: string, subject: string, emailTo: string) => {
    const options = {
        to: emailTo,
        subject,
        html
    };

    try {
        await emailApi.sendEmail(options);

    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const sendPasswordResetEmail = async (emailTo: string, resetPasswordLink: string, name: string) => {
    try {
        const emailHtml = render(ResetPasswordEmail({ resetPasswordLink: resetPasswordLink, name }));
        return sendEmail(emailHtml, "Reset your password", emailTo);
    } catch (err) {
        console.error(err);
        return err;
    }
};

export const sendVerifyEmail = async (emailTo: string, code: string) => {
    const emailHtml = render(VerifyEmail({ code }));
    return sendEmail(emailHtml, "Verify your email", emailTo);
};

export const sendPasswordChangedEmail = async (emailTo: string, name: string) => {
    const emailHtml = render(PasswordChangedEmail({ name, appDomain: `${window.location.origin}` }));
    return sendEmail(emailHtml, "Password changed", emailTo);
};

export const sendDeleteRequestConfirmationEmail = async (emailTo: string, loginUrl: string) => {
    const emailHtml = render(DeleteRequestConfirmationEmail({ loginUrl }));
    return sendEmail(emailHtml, "Account deletion confirmation", emailTo);
};

export const sendErrorReport = async (error: unknown, errorInfo: unknown, feedback: string, url: string, user?: User) => {
    try {
        const emailHtml = render(ErrorReportEmail({ error, errorInfo, user, feedback, url }));
        return sendEmail(emailHtml, "Error Report", "hello@1template.io");
    } catch (err) {
        console.error(err);
        return err;
    }
};

export const sendFeedbackEmail = async (feedback: string, url: string, user?: User) => {
    try {
        const emailHtml = render(FeedbackEmail({ user, feedback, url }));
        return sendEmail(emailHtml, "User Feedback", "hello@1template.io");
    } catch (err) {
        console.error(err);
        return err;
    }
};

export const sendFeedbackSurveyEmail = async (
    user: User,
    url: string,
    satisfaction: number,
    nps: number,
    deviceType: DeviceType,
    browserDetails: string,
    comments?: string,
) => {
    const subject = `Survey Feedback - ${user.email}`;
    const emailHtml = render(SurveyEmail({ user, url, satisfaction, nps, comments, deviceType, browserDetails }));
    return sendEmail(emailHtml, subject, "hello@1template.io");
};
