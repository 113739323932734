import { useQuery } from "@tanstack/react-query";
import {
    AtSign,
    CheckCircle,
    FileIcon,
    RefreshCwIcon,
    UserCheck,
    UserMinus,
    UserPlus,
    Users,
} from "lucide-react";
import { useMemo, useState } from "react";
import {
    experienceLevelColumns,
    industryColumns,
    jobRoleColumns,
    jobTypeColumns,
    productBreakdownColumns,
    resumeLayoutColumns,
} from "./tableDefinitions";
import statsApi, { StatsRequest } from "@/api/stats";
import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { useAppSelector } from "@/hooks/types";
import { StatCard } from "@/pages/Admin/Stats/StatCard";
import { StatTable } from "@/pages/Admin/Stats/StatTable";
import { StatsContainer } from "@/pages/Admin/Stats/StatsContainer";
import {
    calculatePreferenceStats,
    calculateResumeLayoutStats,
} from "@/pages/Admin/Stats/helper";
import {
    jobRoles,
    industries,
    experienceLevels,
    jobTypes,
} from "@/pages/Preferences/constants";
import { getUserDetails } from "@/store/user/selectors";
import { Resume, User } from "@/types";

interface SubscriptionAnalytics {
    total: number;
    active: number;
    cancelled: number;
    trialing: number;
    usedTrial: number;
    productBreakdown: Record<string, number>;
}

const defaultSubscriptionAnalytics: SubscriptionAnalytics = {
    total: 0,
    active: 0,
    cancelled: 0,
    trialing: 0,
    usedTrial: 0,
    productBreakdown: {},
};

interface QuickDateRange {
    label: string;
    getDates: () => { startDate: string; endDate: string };
}

const quickDateRanges: QuickDateRange[] = [
    {
        label: "Last 3 Days",
        getDates: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(end.getDate() - 3);
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
    {
        label: "Last 7 Days",
        getDates: () => {
            const end = new Date();
            const start = new Date();
            start.setDate(end.getDate() - 7);
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
    {
        label: "Last Month",
        getDates: () => {
            const end = new Date();
            const start = new Date();
            start.setMonth(end.getMonth() - 1);
            return {
                startDate: start.toISOString().split("T")[0],
                endDate: end.toISOString().split("T")[0],
            };
        },
    },
    {
        label: "All Time",
        getDates: () => ({
            startDate: "2020-01-01", // Adjust this date as needed
            endDate: new Date().toISOString().split("T")[0],
        }),
    },
];

const defaultQueryParams = {
    refetchOnMount: false,
    staleTime: 0,
    gcTime: 0,
};

export const StatsPage = () => {
    const userDetails = useAppSelector(getUserDetails);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);

    // Update submitted dates type
    const [submittedDates, setSubmittedDates] = useState<{
        startDate: string | null;
        endDate: string | null;
    }>({
        startDate: null,
        endDate: null,
    });

    const params: StatsRequest = useMemo(
        () => ({
            startDate: submittedDates.startDate,
            endDate: submittedDates.endDate,
            userId: userDetails?.id ?? "",
        }),
        [submittedDates, userDetails?.id],
    );

    const {
        data: allResumes = [],
        refetch: refetchResumes,
        isLoading: isResumesLoading,
    } = useQuery<Resume[]>({
        queryKey: ["allResumes"],
        queryFn: () => statsApi.getAllResumes(params),
        ...defaultQueryParams,
    });

    const {
        data: allUsers = [],
        refetch: refetchUsers,
        isLoading: isUsersLoading,
    } = useQuery<User[]>({
        queryKey: ["allUsers"],
        queryFn: () => statsApi.getAllUsers(params),
        ...defaultQueryParams,
    });

    const {
        data: subscriptionAnalytics = defaultSubscriptionAnalytics,
        refetch: refetchSubscriptions,
    } = useQuery<SubscriptionAnalytics>({
        queryKey: ["subscriptionAnalytics"],
        queryFn: async () => {
            const result = await statsApi.getSubscriptionAnalytics(params);
            return result as SubscriptionAnalytics;
        },
        ...defaultQueryParams,
    });

    const isLoading = isResumesLoading || isUsersLoading;

    const handleRefresh = () => {
        refetchResumes();
        refetchUsers();
        refetchSubscriptions();
        // refetchAverageResumes();
    };

    const handleSubmit = () => {
        setSubmittedDates({ startDate, endDate });
        setTimeout(() => {
            handleRefresh(); // Trigger the refresh after updating dates
        }, 300);
    };

    const handleQuickDateSelect = (range: QuickDateRange) => {
        const { startDate: newStartDate, endDate: newEndDate } =
            range.getDates();
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        setSubmittedDates({ startDate: newStartDate, endDate: newEndDate });
        setTimeout(handleRefresh, 300);
    };

    const industryStats = useMemo(
        () => calculatePreferenceStats(allUsers, industries, "industries"),
        [allUsers],
    );

    const jobRoleStats = useMemo(
        () => calculatePreferenceStats(allUsers, jobRoles, "jobRoles"),
        [allUsers],
    );

    const experienceLevelStats = useMemo(
        () =>
            calculatePreferenceStats(
                allUsers,
                experienceLevels,
                "experienceLevel",
            ),
        [allUsers],
    );

    const jobTypeStats = useMemo(
        () => calculatePreferenceStats(allUsers, jobTypes, "jobTypes"),
        [allUsers],
    );

    const resumeLayoutStats = useMemo(
        () => calculateResumeLayoutStats(allResumes),
        [allResumes],
    );

    return (
        <div className="h-full rounded-lg bg-white px-6 pt-2">
            <div className="mb-4 flex flex-wrap items-center justify-between gap-4">
                <div className="flex flex-wrap items-center gap-4">
                    <div className="flex items-center gap-2">
                        <label
                            htmlFor="start-date"
                            className="text-sm font-medium text-gray-700"
                        >
                            Start Date
                        </label>
                        <input
                            type="date"
                            id="start-date"
                            value={startDate ?? ""}
                            onChange={e => setStartDate(e.target.value || null)}
                            className="rounded-md border-gray-300 px-2 py-1 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <div className="flex items-center gap-2">
                        <label
                            htmlFor="end-date"
                            className="text-sm font-medium text-gray-700"
                        >
                            End Date
                        </label>
                        <input
                            type="date"
                            id="end-date"
                            value={endDate ?? ""}
                            onChange={e => setEndDate(e.target.value || null)}
                            className="rounded-md border-gray-300 px-2 py-1 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                    </div>
                    <Button
                        onClick={handleSubmit}
                        disabled={isLoading || !startDate || !endDate}
                        loading={isLoading}
                    >
                        Apply
                    </Button>
                    <div className="flex flex-wrap gap-2">
                        {quickDateRanges.map(range => (
                            <Button
                                key={range.label}
                                variant={ButtonVariant.OUTLINE}
                                color={ButtonColourStyles.OUTLINE_GREY}
                                onClick={() => handleQuickDateSelect(range)}
                                disabled={isLoading}
                                className="!px-1 !py-1 !text-sm"
                            >
                                {range.label}
                            </Button>
                        ))}
                    </div>
                </div>
                <button
                    onClick={handleRefresh}
                    disabled={isLoading}
                    className="inline-flex items-center gap-2 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50"
                >
                    <RefreshCwIcon
                        className={`h-4 w-4 ${isLoading ? "animate-spin" : ""}`}
                    />
                    {isLoading ? "Refreshing..." : "Refresh"}
                </button>
            </div>
            <StatsContainer title="Totals">
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-5">
                    <StatCard
                        title="Resume Created"
                        value={allResumes.length}
                        icon={<FileIcon size={16} />}
                        loading={isResumesLoading}
                    />
                    <StatCard
                        title="Users Created"
                        value={allUsers.length}
                        icon={<Users size={16} />}
                        loading={isUsersLoading}
                    />
                    <StatCard
                        title="Email Users"
                        value={
                            allUsers.filter(
                                user => user.loginMethod === "email",
                            ).length
                        }
                        icon={<Users size={16} />}
                        loading={isUsersLoading}
                    />
                    <StatCard
                        title="SSO Users"
                        value={
                            allUsers.filter(
                                user => user.loginMethod !== "email",
                            ).length
                        }
                        icon={<Users size={16} />}
                        loading={isUsersLoading}
                    />
                    <StatCard
                        title="Opt-In Emails"
                        value={
                            allUsers.filter(user =>
                                user.marketingOptIns.some(
                                    optIn => optIn.channel === "email",
                                ),
                            ).length
                        }
                        icon={<AtSign size={16} />}
                        loading={isUsersLoading}
                    />
                </div>
            </StatsContainer>
            <StatsContainer title="Subscription Analytics">
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-5">
                    <StatCard
                        title="Total"
                        value={subscriptionAnalytics.total}
                        icon={<Users size={16} />}
                    />
                    <StatCard
                        title="Active"
                        value={subscriptionAnalytics.active}
                        icon={<UserPlus size={16} />}
                    />
                    <StatCard
                        title="Cancelled"
                        value={subscriptionAnalytics.cancelled}
                        icon={<UserMinus size={16} />}
                    />
                    <StatCard
                        title="Trialing"
                        value={subscriptionAnalytics.trialing}
                        icon={<UserCheck size={16} />}
                    />
                    <StatCard
                        title="Used Trial"
                        value={subscriptionAnalytics.usedTrial}
                        icon={<CheckCircle size={16} />}
                    />
                    <StatCard title="Product Breakdown">
                        <StatTable
                            columns={productBreakdownColumns}
                            data={Object.entries(
                                subscriptionAnalytics.productBreakdown,
                            ).map(([product, count]) => ({
                                product,
                                count,
                                percentage:
                                    (count / subscriptionAnalytics.total) * 100,
                            }))}
                        />
                    </StatCard>
                </div>
            </StatsContainer>
            <StatsContainer title="User Preferences">
                <div className="flex flex-wrap gap-6">
                    <StatCard title="Industries">
                        <StatTable
                            columns={industryColumns}
                            data={industryStats}
                        />
                    </StatCard>
                    <StatCard title="Job Roles">
                        <StatTable
                            columns={jobRoleColumns}
                            data={jobRoleStats}
                        />
                    </StatCard>
                    <StatCard title="Experience Levels">
                        <StatTable
                            columns={experienceLevelColumns}
                            data={experienceLevelStats}
                        />
                    </StatCard>
                    <StatCard title="Job Types">
                        <StatTable
                            columns={jobTypeColumns}
                            data={jobTypeStats}
                        />
                    </StatCard>
                </div>
            </StatsContainer>
            <StatsContainer title="Resumes">
                <div className="flex flex-wrap gap-6">
                    <StatCard title="Column Layout">
                        <StatTable
                            columns={resumeLayoutColumns}
                            data={resumeLayoutStats}
                        />
                    </StatCard>
                </div>
            </StatsContainer>
        </div>
    );
};
