import clsx from "clsx";
import {
    Camera,
    CircleIcon,
    EditIcon,
    ImageIcon,
    SquareIcon,
} from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useParams } from "react-router-dom";
import { Button, ButtonColourStyles, ButtonVariant } from "../Button/Button";
import { Modal } from "../Modal/Modal";
import { PreviewImage } from "./PreviewImage";
import resumeApi from "@/api/resume";
import { IconButton } from "@/components/Button/IconButton";
import { ImageEditor } from "@/components/Images/ImageEditor";
import { SideBarField } from "@/components/Sidebar/SideBarField";
import { ColourPicker } from "@/components/Sidebar/Styles/ColourPicker";
import { SwitchToggle } from "@/components/SwitchToggle/SwitchToggle";
import { AppText } from "@/components/Text/AppText";
import { InputError } from "@/components/form/InputError";
import { PROFILE_IMAGE_KEY } from "@/constants/resume";
import { getS3URL } from "@/helper/getS3URL";
import { translate } from "@/helper/translate";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import { openModal } from "@/store/modal/slice";
import { DEFAULT_HEADER_IMAGE_PATH } from "@/store/resume/constants";
import {
    getActiveResume,
    getActiveResumeGlobalStyles,
    getActiveResumeProfileImageStyles,
} from "@/store/resume/selectors";
import { getUserDetails } from "@/store/user/selectors";
import { ModalTypes } from "@/types/modal";

const FILE_SIZE_LIMIT = 1024 * 1024 * 5; // 5MB file size

const shapeOptions = [
    {
        id: "circle",
        icon: CircleIcon,
    },
    {
        id: "square",
        icon: SquareIcon,
    },
];

interface UploadImageProps {
    parentClassName: string;
    handleSaveImage:
        | void
        | undefined
        | ((imageUrl: string, imageStyles?: Record<string, any>) => void);
    editable: boolean;
    src?: string;
    color?: string;
    showPreview: boolean;
    handleRemoveImage?: (imageUrl?: string) => void;
    triggerType?: "button" | "iconButton";
    inlineStyles?: string;
}

export const UploadImage = ({
    parentClassName,
    handleSaveImage,
    editable,
    color,
    src,
    showPreview = true,
    handleRemoveImage,
    triggerType,
    inlineStyles,
}: UploadImageProps) => {
    const { id } = useParams();
    const profileImageStyles = useAppSelector(
        getActiveResumeProfileImageStyles,
    );
    const globalStyles = useAppSelector(getActiveResumeGlobalStyles);
    const user = useAppSelector(getUserDetails);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<AvatarEditor | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [resumeImage, setResumeImage] = useState<File | undefined>();
    const [previewUrl, setPreviewUrl] = useState<string | undefined>();
    const [uploadedImageUrl, setUploadedImageUrl] = useState<
        string | undefined
    >();
    const [error, setError] = useState<string | undefined>();
    const [isSaving, setIsSaving] = useState(false);
    const [modalStep, setModalStep] = useState<"upload" | "preview">("preview");
    const [isUploading, setIsUploading] = useState(false);
    const [activeShape, setActiveShape] = useState<string>(
        profileImageStyles.borderRadius === "100%" ? "circle" : "square",
    );
    const [includeBorder, setIncludeBorder] = useState(
        profileImageStyles.borderWidth &&
            profileImageStyles.borderWidth !== "0px",
    );
    const [borderColor, setBorderColor] = useState(
        profileImageStyles.borderColor ?? "#FFFFFF",
    );
    const [isPristine, setIsPristine] = useState(true);
    const previousImage = useRef(src);
    const dispatch = useAppDispatch();
    const activeResume = useAppSelector(getActiveResume);
    const { updateStyles } = useUpdateTemplate();

    useEffect(() => {
        if (resumeImage && !modalOpen) {
            setResumeImage(undefined);
        }
    }, [modalOpen, resumeImage]);

    const handleFileChange = async (image?: File) => {
        if (!image) return;
        if (id && user?.id) {
            // Replaces spaces with underscores
            const cleanFile = new File([image], image.name.replace(/ /g, "_"), {
                type: image.type,
            });

            if (cleanFile.size > FILE_SIZE_LIMIT) {
                setError("File size should be less than 5MB");
                return;
            }

            setResumeImage(cleanFile);
        }
    };

    const getAllUpdatedImageStyles = () => {
        if (!activeResume?.id) return;
        const imageStyles = {
            borderRadius: activeShape === "circle" ? "100%" : "5%",
            borderWidth: includeBorder ? "3px" : "0px",
            borderStyle: includeBorder ? "solid" : "none",
            borderColor: borderColor,
        };
        const newImageStyles = {
            ...activeResume.styles[PROFILE_IMAGE_KEY],
            ...imageStyles,
        };
        return newImageStyles;
    };

    const saveImageStyles = async () => {
        if (!activeResume?.id) return;
        const newImageStyles = getAllUpdatedImageStyles();
        if (!newImageStyles) return;
        updateStyles(PROFILE_IMAGE_KEY, newImageStyles);
    };

    const onUploadImage = async () => {
        if (!editorRef.current || !resumeImage || !id || !user?.id) return;
        setIsUploading(true);
        const dataUrl = editorRef.current.getImageScaledToCanvas().toDataURL();
        const res = await fetch(dataUrl);
        const blob = await res.blob();
        const editedImage = new File([blob], resumeImage.name, {
            type: resumeImage.type,
        });

        await resumeApi
            .uploadResumeImage(id, editedImage)
            .then(res => {
                if (res) {
                    const s3Url = getS3URL(user.id, res.url);
                    setUploadedImageUrl(s3Url);
                    setModalStep("preview");
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }
                }
            })
            .catch(e => {
                if (e.message === "Error: File size should be less than 5MB") {
                    setError("File size should be less than 5MB");
                } else {
                    setError("Failed to upload image");
                }
            })
            .finally(() => {
                setIsUploading(false);
            });
    };

    const onSave = async () => {
        if (!id || !user) return;
        setIsSaving(true);

        if (uploadedImageUrl) {
            const imageStyles = getAllUpdatedImageStyles();
            handleSaveImage?.(uploadedImageUrl, imageStyles);
            handleRemoveImage?.(previousImage.current);
        } else {
            saveImageStyles();
        }
        setIsSaving(false);
        closeImageModal();
    };

    const resetImageStyles = () => {
        setIsPristine(true);
        setModalStep("preview");
        setActiveShape(
            profileImageStyles.borderRadius === "100%" ? "circle" : "square",
        );
        setIncludeBorder(profileImageStyles.borderWidth !== "0px");
        setBorderColor(profileImageStyles.borderColor ?? "#FFFFFF");
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const closeImageModal = () => {
        setModalOpen(false);
        setResumeImage(undefined);
        setPreviewUrl(undefined);
        setUploadedImageUrl(undefined);
        setError(undefined);
    };

    const openImageModal = () => {
        setModalOpen(true);
        dispatch(openModal({ modalType: ModalTypes.EDIT_IMAGE }));
    };

    const onRemoveImage = useCallback(() => {
        handleRemoveImage?.();
        closeImageModal();
    }, [handleRemoveImage]);

    useEffect(() => {
        let objectUrl: string | undefined;
        if (resumeImage) {
            objectUrl = URL.createObjectURL(resumeImage);
            setPreviewUrl(objectUrl);
        }

        return () => {
            if (objectUrl) URL.revokeObjectURL(objectUrl);
        };
    }, [resumeImage]);

    const imageInlineStyles = useMemo(() => {
        let styles = `${inlineStyles} `;
        const newStyles = [];
        if (profileImageStyles.borderWidth) {
            newStyles.push(`border-width: ${profileImageStyles.borderWidth}`);
        }
        if (profileImageStyles.borderStyle) {
            newStyles.push(`border-style: ${profileImageStyles.borderStyle}`);
        }
        if (profileImageStyles.borderColor) {
            newStyles.push(`border-color: ${profileImageStyles.borderColor}`);
        }
        if (profileImageStyles.borderRadius) {
            newStyles.push(`border-radius: ${profileImageStyles.borderRadius}`);
        }
        return styles + newStyles.join("; ");
    }, [inlineStyles, profileImageStyles]);

    const previewImageInlineStyles = useMemo(() => {
        const newStyles = [];
        if (includeBorder) {
            newStyles.push(`border-width: 3px`);
            newStyles.push(`border-style: solid`);
            newStyles.push(`border-color: ${borderColor}`);
        } else {
            newStyles.push(`border-width: 0px`);
        }

        if (activeShape === "circle") {
            newStyles.push(`border-radius: 100%`);
        } else {
            newStyles.push(`border-radius: 5%`);
        }
        return newStyles.join("; ");
    }, [includeBorder, borderColor, activeShape]);

    return (
        <>
            <div className={clsx("relative", parentClassName)}>
                {src && showPreview && (
                    <PreviewImage
                        color={color}
                        handleClick={openImageModal}
                        src={src}
                        editable={editable}
                        inlineStyles={imageInlineStyles}
                    />
                )}
                {triggerType === "button" && (
                    <Button
                        leftIcon={<EditIcon className="h-5 w-5" />}
                        onClick={openImageModal}
                        variant={ButtonVariant.SOLID}
                        color={ButtonColourStyles.SOLID_GREY}
                        className="w-full"
                    >
                        Edit image
                    </Button>
                )}
                {triggerType === "iconButton" && (
                    <IconButton
                        onClick={openImageModal}
                        variant={ButtonVariant.SOLID}
                        color={ButtonColourStyles.SOLID_WHITE}
                    >
                        <Camera size={16} />
                    </IconButton>
                )}
                {editable && (
                    <Modal
                        modalType={ModalTypes.EDIT_IMAGE}
                        open={modalOpen}
                        title={
                            modalStep === "preview"
                                ? "Edit Image"
                                : "Upload Image"
                        }
                        width={modalStep === "preview" ? "lg" : "2xl"}
                        onClose={() => {
                            closeImageModal();
                        }}
                        afterClose={resetImageStyles}
                    >
                        {modalStep === "preview" && (
                            <div className="relative mt-4 flex w-full flex-col">
                                <div className="mx-auto flex gap-10">
                                    <div className="min-h-[200px] w-[250px]">
                                        <AppText
                                            variant="subheadings"
                                            className="mb-2"
                                        >
                                            Options
                                        </AppText>
                                        <div className="flex flex-col gap-5 pr-2">
                                            <SideBarField title="Shape">
                                                <div className="grid h-10 grid-cols-2 gap-2 rounded bg-neutral-100 p-1">
                                                    {shapeOptions.map(shape => (
                                                        <button
                                                            key={shape.id}
                                                            onClick={() => {
                                                                setActiveShape(
                                                                    shape.id,
                                                                );
                                                                setIsPristine(
                                                                    false,
                                                                );
                                                            }}
                                                            className={clsx(
                                                                "group relative flex flex-col items-center justify-center gap-1 rounded-lg p-1",
                                                                shape.id ===
                                                                    activeShape
                                                                    ? "bg-primary-900 text-white hover:bg-primary-800"
                                                                    : "bg-transparent text-neutral-700 hover:bg-primary-200 hover:text-neutral-900",
                                                            )}
                                                        >
                                                            <shape.icon
                                                                size={24}
                                                            />
                                                        </button>
                                                    ))}
                                                </div>
                                            </SideBarField>
                                            <SideBarField title="Border">
                                                <div className="ml-auto">
                                                    <SwitchToggle
                                                        enabled={includeBorder}
                                                        onToggle={enabled => {
                                                            setIncludeBorder(
                                                                enabled,
                                                            );
                                                            setIsPristine(
                                                                false,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </SideBarField>
                                            {includeBorder && (
                                                <SideBarField
                                                    title={`Border ${translate("colour", "title")}`}
                                                >
                                                    <ColourPicker
                                                        onChange={colour => {
                                                            setBorderColor(
                                                                colour,
                                                            );
                                                            setIsPristine(
                                                                false,
                                                            );
                                                        }}
                                                        activeColour={
                                                            borderColor ?? ""
                                                        }
                                                    />
                                                </SideBarField>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        className="mb-10 mt-4 flex w-[140px] items-center justify-center rounded-lg"
                                        style={{
                                            backgroundColor:
                                                globalStyles.backgroundColor,
                                        }}
                                    >
                                        <div className="group relative flex max-h-[112px] w-[112px] items-center justify-center">
                                            <PreviewImage
                                                color={color}
                                                src={
                                                    uploadedImageUrl ||
                                                    src ||
                                                    ""
                                                }
                                                editable={false}
                                                inlineStyles={
                                                    previewImageInlineStyles
                                                }
                                            />
                                            <div className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                                <IconButton
                                                    onClick={() => {
                                                        if (
                                                            fileInputRef.current
                                                        ) {
                                                            fileInputRef.current.value =
                                                                "";
                                                        }
                                                        fileInputRef.current?.click();

                                                        setModalStep("upload");
                                                        setError(undefined);
                                                    }}
                                                    variant={
                                                        ButtonVariant.SOLID
                                                    }
                                                    className="ml-3"
                                                >
                                                    <EditIcon className="h-5 w-5 text-white" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex w-full justify-between">
                                    <Button
                                        className={clsx("mr-3 mt-4")}
                                        onClick={() => {
                                            onRemoveImage();
                                        }}
                                        color={ButtonColourStyles.SOLID_RED}
                                        disabled={src?.includes(
                                            DEFAULT_HEADER_IMAGE_PATH,
                                        )}
                                    >
                                        Remove
                                    </Button>
                                    <div>
                                        <Button
                                            className="mr-3 mt-4"
                                            onClick={() => {
                                                if (fileInputRef.current) {
                                                    fileInputRef.current.value =
                                                        "";
                                                }
                                                fileInputRef.current?.click();
                                                setError(undefined);
                                                setModalStep("upload");
                                            }}
                                            color={
                                                ButtonColourStyles.SOLID_SOFT_INDIGO
                                            }
                                        >
                                            Upload
                                        </Button>
                                        <Button
                                            disabled={!previewUrl && isPristine}
                                            className="mt-4"
                                            loading={isSaving}
                                            onClick={() => {
                                                onSave();
                                            }}
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {modalStep === "upload" && (
                            <div className="relative mt-4 flex w-full flex-col items-center justify-between">
                                <div className="flex min-h-[458px] w-full items-center justify-center">
                                    {previewUrl ? (
                                        <ImageEditor
                                            src={previewUrl || ""}
                                            editorRef={editorRef}
                                            borderRadius={
                                                activeShape === "circle"
                                                    ? 200
                                                    : 5
                                            }
                                        />
                                    ) : (
                                        <Button
                                            color={
                                                ButtonColourStyles.SOLID_SOFT_INDIGO
                                            }
                                            onClick={() => {
                                                fileInputRef.current?.click();
                                                setError(undefined);
                                            }}
                                            leftIcon={<ImageIcon size={16} />}
                                        >
                                            Select Image
                                        </Button>
                                    )}
                                </div>
                                <InputError error={error} />
                                <div className="flex w-full justify-between">
                                    <Button
                                        className={clsx("mr-3 mt-4")}
                                        onClick={() => {
                                            if (fileInputRef.current) {
                                                fileInputRef.current.value = "";
                                            }
                                            setPreviewUrl(undefined);
                                            setModalStep("preview");
                                        }}
                                        color={ButtonColourStyles.SOLID_RED}
                                    >
                                        Cancel
                                    </Button>
                                    <div>
                                        <Button
                                            disabled={!previewUrl}
                                            className="mt-4"
                                            loading={isUploading}
                                            onClick={() => {
                                                onUploadImage();
                                            }}
                                        >
                                            Upload
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <input
                            ref={fileInputRef}
                            className="hidden"
                            type="file"
                            id="avatar"
                            accept=".jpg,.jpeg,.png"
                            onChange={e =>
                                handleFileChange(e.target?.files?.[0])
                            }
                        />
                    </Modal>
                )}
            </div>
        </>
    );
};
