import SystemsImage from "@/assets/images/systems-image.svg";
import { AppText } from "@/components/Text/AppText";

export const DashboardEmptyState = () => (
    <div className="mx-10 mt-6 flex flex-col items-center justify-center gap-2 text-center">
        <img
            src={SystemsImage}
            alt="1Template"
            className="mb-5 w-full max-w-[300px]"
        />
        <AppText variant="subheadings">
            Switch to 1Template on desktop to create and edit resumes
        </AppText>
        <AppText
            variant="labelsbuttons"
            className="px-5"
        >
            You will be able to view and download your already created resumes
            on mobile
        </AppText>
    </div>
);
