import { Section, User } from "@/types";
import { SectionDetails, SectionItemDetails } from "@/types/resume";
import { transformFieldsToProps, getDefaultHiddenFields, transformLayoutFieldsToProps } from "@/utils/section";
import { formatUserName } from "@/utils/string";

export const getDefaultSectionDetails = (
    section: Section,
    positionIndex: number,
    columnIndex: number,
    hideImage?: boolean,
    userDetails?: User,
): SectionDetails => {
    const hideHeaderImageField = section.headerFields.some(field => field.name === "image") && hideImage;
    const hideBodyImageField = section.bodyFields.some(field => field.name === "image") && hideImage;
    const hiddenFields = getDefaultHiddenFields(section.headerFields);
    if (hideHeaderImageField || hideBodyImageField) {
        hiddenFields.push("image");
    }

    const name = formatUserName(userDetails);

    return {
        header: transformFieldsToProps(false, section.headerFields, userDetails ? { name: `<h1><strong>${name}</strong></h1>`, email: userDetails?.email } : undefined),
        body: [transformFieldsToProps(true, section.bodyFields)] as SectionItemDetails[],
        hiddenFields,
        activeWidth: section.supportedWidths.includes("full") ? "full" : section.supportedWidths[0],
        columnIndex: columnIndex,
        positionIndex,
        sectionName: section.name,
        layout: transformLayoutFieldsToProps(section.layoutFields),
        sectionConfigId: section.id,
    };
};