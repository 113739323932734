/**
 * On paste events for the editors
 * 1. remove zero-width spaces and replace other special spaces with " "
 * 2. split the string by either </p><p> or \n
 * 3. if a line already has complete p tags with potential attributes, leave it as is
 * 4. if a line doesn't have complete p tags, add opening and closing p tags
 * @param input - The input string to format
 * @returns The formatted string
 */
export const formatPastedContent = (input: string, isHtml: boolean): string => {
    const SPACE_REGEX =
        /(\xA0|&ensp;|\u2002|&emsp;|\u2003|&thinsp;|\u2009|\u200A)/g;
    const ZERO_WIDTH_REGEX = /(\u200B|&#xfeff;)/g;

    const LINE_BREAK_REGEX = /\r\n|\r|\n/g;

    const result = input
        .replace(ZERO_WIDTH_REGEX, "")
        .replace(SPACE_REGEX, " ");

    // Only process spaces if the input is not HTML
    if (!isHtml) {
        return result;
    }

    const lines = result.split(LINE_BREAK_REGEX);

    const linesWithParagraphs = lines
        .filter(line => line.trim().length > 0)
        .map(line => {
            const hasCompleteTag = /^<p[^>]*>.*<\/p>$/.test(line);
            if (hasCompleteTag) {
                return line;
            }

            const cleanLine = line
                .replace(/^<p([^>]*)>/, "")
                .replace(/<\/p>$/, "")
                .trim();
            return `<p>${cleanLine}</p>`;
        });

    return linesWithParagraphs.join("");
};
