import { CheckboxRadioContainer } from "@/components/form/CheckboxRadioContainer";
import clsx from "clsx";

interface RadioProps {
    id: string;
    label: string;
    description?: string;
    onChange: (checked: boolean) => void;
    checked: boolean;
    className?: string;
    borderless?: boolean;
    vertical?: boolean;
}

export const Radio = ({
    id,
    label,
    description,
    checked,
    onChange,
    className,
    borderless,
    vertical,
}: RadioProps) => {
    return (
        <CheckboxRadioContainer
            id={id}
            label={label}
            description={description}
            className={clsx(className)}
            borderless={borderless}
            vertical={vertical}
        >
            <input
                id={id}
                name={id}
                type="radio"
                checked={checked}
                value={id}
                className="h-4 w-4 cursor-pointer rounded-full border-2 border-neutral-900 text-neutral-900 outline-none focus:ring-transparent"
                onChange={e => {
                    onChange(e.currentTarget.checked);
                }}
            />
        </CheckboxRadioContainer>
    );
};
