import { Alert } from "@/components/Alert/Alert";
import {
    ButtonVariant,
    ButtonColourStyles,
    Button,
} from "@/components/Button/Button";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { LaunchPromotion } from "@/components/Plans/LaunchPromotion";
import { Plans } from "@/components/Plans/Plans";
import { AppText } from "@/components/Text/AppText";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useStripe } from "@/hooks/useStripe";
import { PlanSummary } from "@/pages/Profile/PlanSummary";
import { setShowPlansModal } from "@/store/app/slice";
import {
    getStripeCustomerId,
    getUserActivePlan,
    getUserHasUsedTrial,
    getUserPlanEndingSoon,
    getUserPlanStatusIssue,
} from "@/store/user/selectors";
import { AppRoutes } from "@/types/routes";
import { META_CUSTOM_EVENT_ACTIONS } from "@/types/tracking";
import { trackMetaCustomEvent } from "@/utils/tracking";
import { pluralise } from "@/utils/string";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export const ProfilePlan = () => {
    const stripeCustomerId = useAppSelector(getStripeCustomerId);
    const [portalUrl, setPortalUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(!portalUrl);
    const userPlan = useAppSelector(getUserActivePlan);
    const planEndingSoon = useAppSelector(getUserPlanEndingSoon);
    const planStatusIssue = useAppSelector(getUserPlanStatusIssue);
    const [searchParams, setSearchParams] = useSearchParams();
    const [refresh, setRefresh] = useState(
        searchParams.get("refresh") === "true",
    );
    const { getAndUpdateUserPlan, createCustomerPortalSession } = useStripe();
    const [showSuccess, setShowSuccess] = useState(
        searchParams.get("res") === "success",
    );
    const dispatch = useAppDispatch();
    const trialPeriodDays = import.meta.env.VITE_STRIPE_TRIAL_PERIOD_DAYS;
    const hasUsedTrial = useAppSelector(getUserHasUsedTrial);
    const canApplyTrial = useMemo(() => {
        return Number(trialPeriodDays) > 0 && !hasUsedTrial;
    }, [trialPeriodDays, hasUsedTrial]);

    const getUserPlan = useCallback(async () => {
        await getAndUpdateUserPlan();
        setLoading(false);
    }, [getAndUpdateUserPlan]);

    useEffect(() => {
        if (refresh) {
            setLoading(true);
            getUserPlan();
            setRefresh(false);
            setSearchParams({}, { replace: true });
        }
    }, [refresh, getUserPlan, setRefresh, setSearchParams]);

    useEffect(() => {
        if (showSuccess) {
            dispatch(setShowPlansModal("upgrade"));
            setShowSuccess(false);
        }
    }, [showSuccess, setSearchParams, dispatch]);

    useEffect(() => {
        const getPortalSession = async () => {
            const url = await createCustomerPortalSession(
                `${window.location.origin}/${AppRoutes.Profile}?tab=plan&refresh=true`,
            );
            setPortalUrl(url);
            setLoading(false);
        };

        getPortalSession();
    }, [createCustomerPortalSession, stripeCustomerId]);

    const trackButton = (action: META_CUSTOM_EVENT_ACTIONS) => {
        trackMetaCustomEvent(action);
    };

    return (
        <div className="flex flex-col">
            <AppText
                variant="headings"
                className="mb-8 hidden text-neutral-900 md:block"
            >
                Plan
            </AppText>
            {loading ? (
                <div className="m-auto my-40">
                    <LoadingSpinner />
                </div>
            ) : (
                <>
                    {planEndingSoon && (
                        <div className="mb-4">
                            <Alert
                                type="warning"
                                actionLabel="Renew subscription"
                                actionLink={portalUrl || "#"}
                                message={
                                    <AppText variant="labelsbuttons">
                                        Your plan is scheduled to end in{" "}
                                        {userPlan?.details?.daysBeforeEnd}{" "}
                                        {pluralise(
                                            userPlan?.details?.daysBeforeEnd ||
                                                0,
                                            "day",
                                            "days",
                                        )}
                                        .
                                    </AppText>
                                }
                            />
                        </div>
                    )}
                    {planStatusIssue && (
                        <div className="mb-4">
                            <Alert
                                type="error"
                                actionLabel={
                                    "Manage " +
                                    (userPlan?.planStatus === "paused"
                                        ? "subscription"
                                        : "billing")
                                }
                                actionLink={portalUrl || "#"}
                                message={
                                    <AppText variant="labelsbuttons">
                                        Your subscription is currently{" "}
                                        {userPlan?.planStatus === "paused"
                                            ? "paused. To renew your subscription, please reactivate your plan."
                                            : "inactive due to a payment issue. Please update your billing information to reactivate your plan."}
                                    </AppText>
                                }
                            />
                        </div>
                    )}
                    {userPlan?.isSubscribed && <PlanSummary />}
                    {!userPlan?.isSubscribed && !planStatusIssue && (
                        <>
                            <AppText
                                variant="subheadings"
                                className="mb-2 text-neutral-900"
                            >
                                Plans
                            </AppText>
                            <LaunchPromotion />
                            <div className="mt-4">
                                <Plans
                                    isProfile
                                    showFreePlan={canApplyTrial}
                                    trialPeriodDays={
                                        canApplyTrial ? trialPeriodDays : 0
                                    }
                                />
                            </div>
                        </>
                    )}
                    {userPlan?.isSubscribed &&
                        userPlan?.planStatus === "active" &&
                        !planStatusIssue &&
                        userPlan?.details?.interval === "Weekly" && (
                            <div className="mb-8 md:mb-0">
                                <AppText
                                    variant="subheadings"
                                    className="my-4 text-neutral-900"
                                >
                                    Upgrade Plan
                                </AppText>
                                <AppText
                                    className="mb-4 block text-neutral-700"
                                    variant="labelsbuttons"
                                >
                                    Want to upgrade your plan to create
                                    unlimited resumes?
                                </AppText>
                                <Button
                                    to={portalUrl || "#"}
                                    className="float-end mb-4"
                                    variant={ButtonVariant.SOLID}
                                    color={ButtonColourStyles.SOLID_GREY}
                                    disabled={!portalUrl}
                                    onClick={() =>
                                        trackButton(
                                            META_CUSTOM_EVENT_ACTIONS.UPGRADE,
                                        )
                                    }
                                >
                                    Upgrade plan
                                </Button>
                            </div>
                        )}
                    <div className="mb-8 md:mb-0">
                        <AppText
                            variant="subheadings"
                            className="my-4 text-neutral-900"
                        >
                            Manage Plan
                        </AppText>
                        <AppText
                            className="mb-4 block text-neutral-700"
                            variant="labelsbuttons"
                        >
                            Manage and update your billing information and
                            subscriptions.
                        </AppText>
                        <Button
                            to={portalUrl || "#"}
                            className="float-end mb-4"
                            variant={ButtonVariant.SOLID}
                            color={ButtonColourStyles.SOLID_GREY}
                            disabled={!portalUrl}
                            onClick={() =>
                                trackButton(META_CUSTOM_EVENT_ACTIONS.MANAGE)
                            }
                        >
                            Manage plan
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
