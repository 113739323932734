import { AccountSettingsButton } from "@/components/Navigation/AccountSettingsButton";
import { AppText } from "@/components/Text/AppText";
import { logout } from "@/helper/logout";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getIsAdminUser } from "@/store/user/selectors";
import { AppRoutes, SiteRoutes } from "@/types/routes";
import { getIsPreviewPage } from "@/utils/routes";
import { AnimatePresence, motion } from "framer-motion";
import { LogOut } from "lucide-react";
import { useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";

interface MobileMenuProps {
    isOpen: boolean;
    onClose: () => void;
}

export const MobileMenu = ({ isOpen, onClose }: MobileMenuProps) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isAdmin = useAppSelector(getIsAdminUser);
    const isPreviewPage = getIsPreviewPage();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "unset";
        }

        return () => {
            document.body.style.overflow = "unset";
        };
    }, [isOpen]);

    const handleLogout = () => {
        logout(dispatch, navigate);
        queryClient.clear();
        onClose();
    };

    const items = useMemo(
        () => [
            { label: "Account", to: AppRoutes.Profile },
            { label: "Dashboard", to: AppRoutes.Dashboard },
            ...(isAdmin ? [{ label: "Admin", to: "/admin/sections" }] : []),
        ],
        [isAdmin],
    );

    const goToPage = useCallback(
        (to: string) => {
            navigate(to);
            onClose();
        },
        [navigate, onClose],
    );

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0, y: "-100%" }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: "-100%" }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    className="fixed inset-0 z-10 bg-white md:hidden"
                    style={{
                        width: isPreviewPage ? "100vw" : "",
                        height: isPreviewPage ? "100vh" : "",
                    }}
                >
                    <div className="flex h-full flex-col">
                        <div className="absolute right-3 top-16">
                            <AccountSettingsButton onClick={onClose} />
                        </div>
                        <div className="flex-grow overflow-y-auto pt-24">
                            <nav className="flex flex-col px-4 py-2">
                                {items.map(item => (
                                    <a
                                        key={item.to}
                                        className="flex w-full items-center border-b border-gray-100 px-4 py-4 text-neutral-900 transition-colors duration-150 ease-in-out hover:text-neutral-800 active:bg-gray-50 active:text-neutral-800"
                                        onClick={() => goToPage(item.to)}
                                    >
                                        <AppText
                                            variant="contextheading"
                                            className="!font-medium"
                                        >
                                            {item.label}
                                        </AppText>
                                    </a>
                                ))}
                            </nav>
                            <div className="mb-4 mt-8 flex flex-col items-center space-y-2 px-4">
                                <div className="flex justify-center space-x-4">
                                    <a
                                        className="text-neutral-700 hover:text-neutral-900"
                                        href={SiteRoutes.PrivacyPolicy}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <AppText
                                            variant="labelsbuttons"
                                            className="font-medium"
                                        >
                                            Privacy Policy
                                        </AppText>
                                    </a>
                                    <a
                                        className="text-neutral-700 hover:text-neutral-900"
                                        href={SiteRoutes.Terms}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <AppText
                                            variant="labelsbuttons"
                                            className="font-medium"
                                        >
                                            Terms
                                        </AppText>
                                    </a>
                                </div>
                                <a
                                    className="text-neutral-700 hover:text-neutral-900"
                                    href="mailto:hello@1template.io"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <AppText
                                        variant="labelsbuttons"
                                        className="font-medium"
                                    >
                                        Contact Us
                                    </AppText>
                                </a>
                            </div>
                        </div>
                        <div
                            className="border-t p-4"
                            onClick={handleLogout}
                        >
                            <AppText
                                variant="labelsbuttons"
                                className="group flex cursor-pointer items-center justify-center gap-x-2 rounded-lg px-4 py-2 text-error-500 hover:bg-gray-50 hover:text-error-400"
                            >
                                <LogOut
                                    className="h-4 w-4 shrink-0 text-error-500 group-hover:text-error-400"
                                    aria-hidden="true"
                                />
                                Sign out
                            </AppText>
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
