import { DownloadIcon, Edit3 } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { Alert } from "@/components/Alert/Alert";
import {
    ButtonVariant,
    ButtonColourStyles,
    Button,
} from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { FeedbackSurveyButton } from "@/components/FeedbackSurvey/FeedbackSurveyButton";
import { LoadingSpinner } from "@/components/LoadingSpinner/LoadingSpinner";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { PdfDocumentViewer } from "@/components/PdfDocument/PdfDocumentViewer";
import { PdfDownloadButton } from "@/components/PdfDocument/PdfDownloadButton";
import { PdfDocumentPreview } from "@/components/PdfPreview/PdfDocumentPreview";
import { ResumeName } from "@/components/ResumeName/ResumeName";
import { TemplateLoader } from "@/components/TemplateLoader/TemplateLoader";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useDevice } from "@/hooks/useDevice";
import { useStripe } from "@/hooks/useStripe";
import { getTemplateLoadingState } from "@/store/app/selectors";
import { setShowPlansModal } from "@/store/app/slice";
import { A4_PAGE_WIDTH_PIXELS } from "@/store/pages/constants";
import { getPages } from "@/store/pages/selectors";
import {
    getActiveResume,
    getActiveResumeHeaderImage,
    getSectionsByDocumentTypeId,
} from "@/store/resume/selectors";
import { getUserHasActiveSubscription } from "@/store/user/selectors";
import { TemplateLoadingState } from "@/types/app";
import { DeviceContextType } from "@/types/device";

const LoadingComponent = () => {
    return (
        <div
            className="mt-10 flex items-center justify-center"
            style={{ maxWidth: "calc(100vw - 24px)" }}
        >
            <LoadingSpinner />
        </div>
    );
};

export const PreviewPage = () => {
    const activeResume = useAppSelector(getActiveResume);
    const { id, name } = activeResume ?? {};
    const [loading, setLoading] = useState(true);
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [canDownloadFile, setCanDownloadFile] = useState(false);
    const [loadPdf, setLoadPdf] = useState(false);
    const loadingState = useAppSelector(getTemplateLoadingState);
    const userHasActiveSubscription = useAppSelector(
        getUserHasActiveSubscription,
    );
    const { getAndUpdateUserPlan } = useStripe();
    const sectionsConfig = useAppSelector(state =>
        getSectionsByDocumentTypeId(state, activeResume?.documentType ?? ""),
    );
    const pages = useAppSelector(getPages);
    const activeResumeImageSignedUrl = useAppSelector(
        getActiveResumeHeaderImage,
    );
    const { isDesktop } = useDevice() as DeviceContextType;
    const numberOfPages = useMemo(() => pages?.length ?? 0, [pages]);
    const dispatch = useAppDispatch();

    const handleDownloadAttempt = async () => {
        // User has tried to download, but they are likely not subscribed
        // so we should redirect them to the pricing page
        // Get the latest plan
        setLoading(true);
        try {
            const plan = await getAndUpdateUserPlan();
            if (!plan?.isSubscribed) {
                dispatch(setShowPlansModal("preview"));
            }
        } catch (error) {
            console.error("Error getting user plan:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (
            activeResume &&
            loadingState === TemplateLoadingState.READY &&
            pages?.[0].sections.length > 0
        ) {
            setLoading(false);
            setLoadPdf(true);
        }
    }, [activeResume, loadingState, pages]);

    useEffect(() => {
        if (!loading && pdfLoaded && userHasActiveSubscription) {
            setCanDownloadFile(true);
        }
    }, [userHasActiveSubscription, loading, pdfLoaded]);

    return (
        <>
            <PageHelmet title="Preview" />
            <div>
                <div className="border-b-2 border-neutral-300 bg-white px-3 py-4">
                    <div
                        className="mx-auto flex items-center justify-between"
                        style={{ maxWidth: "calc(100vw - 24px)" }}
                    >
                        <ResumeName
                            resumeId={id}
                            resumeName={name}
                            showSaveButton={true}
                        />
                        <div className="flex items-center gap-2">
                            <Button
                                to={`/resume/${id}`}
                                color={ButtonColourStyles.SOLID_SOFT_INDIGO}
                                leftIcon={<Edit3 size={20} />}
                                className="hidden lg:flex"
                            >
                                Edit
                            </Button>
                            {canDownloadFile ? (
                                <PdfDownloadButton
                                    resume={activeResume}
                                    sectionsConfig={sectionsConfig}
                                    pages={pages}
                                    imageSrc={activeResumeImageSignedUrl}
                                    onError={() => {
                                        console.error("Error downloading PDF");
                                    }}
                                    buttonText="Download"
                                    responsive
                                    isLoading={loading}
                                />
                            ) : (
                                <>
                                    <IconButton
                                        onClick={handleDownloadAttempt}
                                        variant={ButtonVariant.OUTLINE}
                                        color={
                                            ButtonColourStyles.OUTLINE_PRIMARY
                                        }
                                        className="flex lg:hidden"
                                        disabled={loading}
                                        loading={loading}
                                    >
                                        <DownloadIcon size={20} />
                                    </IconButton>
                                    <Button
                                        onClick={handleDownloadAttempt}
                                        variant={ButtonVariant.OUTLINE}
                                        color={
                                            ButtonColourStyles.OUTLINE_PRIMARY
                                        }
                                        className="hidden min-w-0 lg:flex lg:min-w-[132px] xl:min-w-[148px]"
                                        leftIcon={<DownloadIcon size={20} />}
                                        loading={loading}
                                        disabled={loading}
                                    >
                                        Download
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <main className="flex flex-col md:items-center">
                {!isDesktop && (
                    <div className="mx-auto mt-2 max-w-[300px] md:max-w-[500px]">
                        <Alert
                            type="info"
                            message="This is a read only view. Please visit 1Template on a desktop device to edit."
                            dismissable
                        />
                    </div>
                )}
                <div className="mt-2 max-w-[100%] flex-grow overflow-x-scroll px-3">
                    <div
                        className="relative mb-3 lg:mb-8"
                        style={{
                            width: A4_PAGE_WIDTH_PIXELS,
                        }}
                    >
                        {(loading || !activeResume) && (
                            <TemplateLoader overlay={<LoadingComponent />} />
                        )}
                        {loadPdf && (
                            <div className="mb-8">
                                <PdfDocumentPreview
                                    onLoadSuccess={() => {
                                        setPdfLoaded(true);
                                    }}
                                    numberOfPages={numberOfPages}
                                    scale={1}
                                    loadingComponent={<LoadingComponent />}
                                >
                                    <PdfDocumentViewer
                                        resume={activeResume}
                                        sectionsConfig={sectionsConfig}
                                        pages={pages}
                                        imageSrc={activeResumeImageSignedUrl}
                                    />
                                </PdfDocumentPreview>
                            </div>
                        )}
                    </div>
                </div>
                <FeedbackSurveyButton />
            </main>
        </>
    );
};
