import { resetAppState } from "@/routes/utils/resetAppState";
import { AppDispatch } from "@/store/store";
import { AppRoutes } from "@/types/routes";
import { trackClarityIdentify } from "@/utils/tracking";
import { NavigateFunction } from "react-router-dom";

export const logout = (dispatch: AppDispatch, navigate: NavigateFunction) => {
    // Reset app state
    resetAppState(dispatch);
    trackClarityIdentify(null);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    navigate(`/${AppRoutes.Login}`, { replace: true });
};