import { AppDispatch } from "@/store/store";
import userApi from "@/api/user";
import { setUserDetails } from "@/store/user/slice";
import { setShowFeedbackSurvey } from "@/store/app/slice";
import { UserFlags, USER_FLAGS } from "@/types/user";

export const handleShowSurvey = async (dispatch: AppDispatch, userId: string, userFlags?: UserFlags) => {
    const currentDownloadCount = Number(
        userFlags?.[USER_FLAGS.DOWNLOAD_COUNT] ?? 0,
    );

    const currentFeedbackSurveyTimestamp = userFlags?.[
        USER_FLAGS.FEEDBACK_SURVEY_TIMESTAMP
    ] as string | null;

    const newFlags: UserFlags = {
        ...(userFlags || {}),
        [USER_FLAGS.DOWNLOAD_COUNT]: currentDownloadCount + 1,
    };

    let canShowSurvey = false;

    const lastSurveyTime = currentFeedbackSurveyTimestamp
        ? new Date(currentFeedbackSurveyTimestamp).getTime()
        : 0;

    const daysSinceLastSurvey = lastSurveyTime
        ? Math.floor((Date.now() - lastSurveyTime) / (1000 * 60 * 60 * 24))
        : null;

    const hasThirtyDaysPassed =
        !lastSurveyTime ||
        daysSinceLastSurvey === null ||
        daysSinceLastSurvey > 30;

    if (currentDownloadCount + 1 > 2) {
        // Over 2 downloads
        if (!currentFeedbackSurveyTimestamp) {
            // First time showing survey
            canShowSurvey = true;
            newFlags[USER_FLAGS.FEEDBACK_SURVEY_TIMESTAMP] =
                new Date().toISOString();
        } else if (hasThirtyDaysPassed) {
            // Show survey if last shown more than 30 days ago
            canShowSurvey = true;
            newFlags[USER_FLAGS.FEEDBACK_SURVEY_TIMESTAMP] =
                new Date().toISOString();
        }
    }

    const newUser = await userApi.saveUserApi(
        {
            flags: newFlags,
        },
        userId,
    );

    dispatch(setUserDetails(newUser));

    if (canShowSurvey) {
        setTimeout(() => {
            dispatch(setShowFeedbackSurvey(true));
        }, 1000);
    }
};
