import { useQueryClient } from "@tanstack/react-query";
import clsx from "clsx";
import {
    ChevronLeft,
    CircleHelp,
    CreditCard,
    LogOut,
    ShieldIcon,
    UserIcon,
} from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FeedbackSurveyButton } from "@/components/FeedbackSurvey/FeedbackSurveyButton";
import { NavigationLink } from "@/components/Navigation/NavigationLink";
import { PageFooter } from "@/components/PageFooter/PageFooter";
import { PageHelmet } from "@/components/PageHelmet/PageHelmet";
import { AppText } from "@/components/Text/AppText";
import { logout } from "@/helper/logout";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useDevice } from "@/hooks/useDevice";
import { ProfileContact } from "@/pages/Profile/ProfileContact";
import { ProfileDetails } from "@/pages/Profile/ProfileDetails";
import { ProfilePlan } from "@/pages/Profile/ProfilePlan";
import { SecurityAndPrivacy } from "@/pages/Profile/SecurityAndPrivacy";
import { getUserPlanRequiresAttention } from "@/store/user/selectors";
import { DeviceContextType } from "@/types/device";
import { SiteRoutes } from "@/types/routes";
import { isFeatureEnabled } from "@/utils/featureFlags";

const navigation = [
    { id: "profile", name: "Profile", icon: UserIcon, order: 1 },
    { id: "security", name: "Security & Privacy", icon: ShieldIcon, order: 3 },
    { id: "contact", name: "Contact", icon: CircleHelp, order: 4 },
];

if (isFeatureEnabled("VITE_FEATURE_STRIPE")) {
    navigation.push({ id: "plan", name: "Plan", icon: CreditCard, order: 2 });
}

const getSortedNavigation = () => {
    return navigation.sort((a, b) => a.order - b.order);
};

export const ProfilePage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { isDesktop } = useDevice() as DeviceContextType;
    const [activeTab, setActiveTab] = useState<string | null>(
        isDesktop ? "profile" : null,
    );
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const planRequiresAttention = useAppSelector(getUserPlanRequiresAttention);
    const navItems = useMemo(() => getSortedNavigation(), []);
    const queryClient = useQueryClient();

    const handleLogout = useCallback(() => {
        logout(dispatch, navigate);
        queryClient.clear();
    }, [dispatch, navigate, queryClient]);

    const handleTabClick = useCallback((tabId: string) => {
        setActiveTab(tabId);
    }, []);

    const handleBack = useCallback(() => {
        setActiveTab(null);
    }, []);
    const getActiveItemName = () => {
        return navItems.find(item => item.id === activeTab)?.name || "";
    };
    useEffect(() => {
        const paramTab = searchParams.get("tab");
        if (paramTab !== activeTab && paramTab) {
            setActiveTab(paramTab);
            setSearchParams({}, { replace: true });
        }
    }, [activeTab, searchParams, setSearchParams]);

    return (
        <div className="flex min-h-screen flex-col">
            <PageHelmet
                title="Profile"
                description="Manage your profile, security, and plan"
                robotsOverride="noindex, nofollow"
            />
            <div className="relative flex flex-grow justify-center overflow-hidden px-4">
                <div className="my-3 flex w-full max-w-[1000px] flex-col rounded-lg md:my-10 md:min-h-[calc(100vh-160px)] md:flex-row md:bg-white">
                    <div className="rounded-lg bg-white md:hidden">
                        {activeTab === null ? (
                            <div className="flex-none">
                                <ul className="divide-y divide-neutral-200">
                                    {navItems.map(item => (
                                        <li
                                            key={item.id}
                                            className="relative"
                                        >
                                            <button
                                                onClick={() =>
                                                    handleTabClick(item.id)
                                                }
                                                className="flex w-full items-center px-4 py-4 text-left"
                                            >
                                                <item.icon className="mr-3 h-5 w-5 text-neutral-900" />
                                                <AppText
                                                    variant="contextheading"
                                                    className="flex-grow font-medium"
                                                >
                                                    {item.name}
                                                </AppText>
                                                <ChevronLeft className="h-5 w-5 rotate-180 transform text-neutral-900" />
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <div>
                                <button
                                    onClick={handleBack}
                                    className="flex items-center px-4 py-4 text-neutral-900"
                                >
                                    <ChevronLeft className="mr-1 h-4 w-4" />
                                    <AppText variant="contextheading">
                                        {getActiveItemName()}
                                    </AppText>
                                </button>
                                <div className="px-4">
                                    {activeTab === "profile" && (
                                        <ProfileDetails />
                                    )}
                                    {activeTab === "security" && (
                                        <SecurityAndPrivacy />
                                    )}
                                    {activeTab === "plan" && <ProfilePlan />}
                                    {activeTab === "contact" && (
                                        <ProfileContact />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="hidden flex-col md:flex md:w-80">
                        <div className="flex grow flex-col gap-y-5 px-4 py-4">
                            <nav className="flex flex-1 flex-col">
                                <AppText
                                    variant="subheadings"
                                    className="mb-3 text-neutral-900"
                                >
                                    Settings
                                </AppText>
                                <ul
                                    role="list"
                                    className="space-y-3"
                                >
                                    {navItems.map(item => (
                                        <li
                                            key={item.id}
                                            onClick={() =>
                                                setActiveTab(item.id)
                                            }
                                        >
                                            <AppText
                                                variant="labelsbuttons"
                                                className={clsx(
                                                    activeTab === item.id
                                                        ? "bg-primary-50 text-neutral-900"
                                                        : "hover:bg-gray-50 ",
                                                    "group flex cursor-pointer items-center gap-x-2 rounded-lg px-4 py-2",
                                                )}
                                            >
                                                <div className="relative">
                                                    <item.icon
                                                        className={clsx(
                                                            activeTab ===
                                                                item.id &&
                                                                "text-neutral-900",
                                                            "h-4 w-4 shrink-0",
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {planRequiresAttention &&
                                                        item.id === "plan" && (
                                                            <span className="absolute -right-1 -top-1 h-2 w-2 rounded-full bg-error-500"></span>
                                                        )}
                                                </div>
                                                {item.name}
                                            </AppText>
                                        </li>
                                    ))}
                                    <li onClick={handleLogout}>
                                        <AppText
                                            variant="labelsbuttons"
                                            className="group flex cursor-pointer items-center gap-x-2 rounded-lg px-4 py-2 text-error-500 hover:bg-gray-50 hover:text-error-400"
                                        >
                                            <LogOut
                                                className="h-4 w-4 shrink-0 text-error-500 group-hover:text-error-400"
                                                aria-hidden="true"
                                            />
                                            Sign out
                                        </AppText>
                                    </li>
                                </ul>
                            </nav>
                            <ul>
                                <li>
                                    <NavigationLink
                                        to={SiteRoutes.PrivacyPolicy}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="!px-0"
                                    >
                                        Privacy Policy
                                    </NavigationLink>
                                </li>
                                <li>
                                    <NavigationLink
                                        to={SiteRoutes.Terms}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="!px-0"
                                    >
                                        Terms of Service
                                    </NavigationLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <main className="no-scrollbar hidden w-full overflow-y-scroll py-6 md:block">
                        <div className="px-4 md:px-12">
                            {activeTab === "profile" && <ProfileDetails />}
                            {activeTab === "security" && <SecurityAndPrivacy />}
                            {activeTab === "plan" && <ProfilePlan />}
                            {activeTab === "contact" && <ProfileContact />}
                        </div>
                    </main>
                </div>
            </div>
            <FeedbackSurveyButton />
            <PageFooter />
        </div>
    );
};
