import { ScrollRestoration, useOutlet, useParams } from "react-router-dom";
import { Navigation } from "@/components/Navigation/Navigation";
import clsx from "clsx";
import {
    getIsAResumePreviewPage,
    getIsNewResumePage,
    getIsPreferencesPage,
    getIsPreviewPage,
    getIsProfilePage,
    getIsResumePage,
} from "@/utils/routes";
import { PlansModal } from "@/components/Modals/PlansModal/PlansModal";
import { useAppData } from "@/hooks/useAppData";
import { LoadingPage } from "@/components/LoadingPage/LoadingPage";

export const AppLayout = () => {
    const outlet = useOutlet();
    const isResumePage = getIsResumePage();
    const isNewResumePage = getIsNewResumePage();
    const isAResumePreviewPage = getIsAResumePreviewPage();
    const isPreferencesPages = getIsPreferencesPage();
    const isPreviewPage = getIsPreviewPage();
    const isProfilePage = getIsProfilePage();
    const params = useParams();
    const { isLoading: isAppDataLoading } = useAppData(params.id, isResumePage);

    return isAppDataLoading ? (
        <LoadingPage />
    ) : (
        <>
            <div
                className={clsx(
                    "relative bg-neutral-100",
                    (isAResumePreviewPage || isPreviewPage || isProfilePage) &&
                        "min-h-full",
                )}
            >
                {!isNewResumePage && !isPreferencesPages && <Navigation />}
                <div
                    className={clsx(
                        isResumePage ? "bg-neutral-100" : "",
                        "relative",
                    )}
                >
                    <ScrollRestoration />
                    {outlet}
                </div>
            </div>
            <PlansModal />
        </>
    );
};
