import { NewSectionPreview } from "@/components/Modals/AddNewSectionModal/NewSectionPreview";
import { IconButton } from "@/components/Button/IconButton";
import Chip from "@/components/Chip/Chip";
import { Modal } from "../../Modal/Modal";
import { AppText } from "@/components/Text/AppText";
import { getDefaultSectionsDataDocumentType } from "@/helper/getDefaultSectionsData";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { useUpdateTemplate } from "@/hooks/useUpdateTemplate";
import {
    getActiveResumeDocumentType,
    getActiveResumeStyles,
    getUnusedSections,
    getUsedSectionsConfigs,
} from "@/store/resume/selectors";
import {
    NotificationMessageType,
    NotificationType,
    ResumeSections,
} from "@/types";
import { TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { PlusSquareIcon } from "lucide-react";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { getIsModalOpen } from "@/store/modal/selectors";
import { ModalTypes } from "@/types/modal";
import { closeModal } from "@/store/modal/slice";
import { TabButton } from "@/components/Tabs/TabButton";
import { addNotification } from "@/store/app/slice";

const AddNewSectionModal = () => {
    const isOpen = useAppSelector(getIsModalOpen(ModalTypes.ADD_NEW_SECTION));
    const [tabIndex, setTabIndex] = useState(0);
    const [isAddingSection, setIsAddingSection] = useState<boolean>(false);
    const usedSections = useAppSelector(getUsedSectionsConfigs);
    const unusedSections = useAppSelector(getUnusedSections);
    const documentType = useAppSelector(getActiveResumeDocumentType);
    const { addSection } = useUpdateTemplate();
    const {
        unusedSectionDetails,
        usedSectionDetails,
    }: {
        unusedSectionDetails: ResumeSections;
        usedSectionDetails: ResumeSections;
    } = useMemo(() => {
        const unusedSectionDetails = getDefaultSectionsDataDocumentType(
            unusedSections,
            documentType!,
        );
        const usedSectionDetails = getDefaultSectionsDataDocumentType(
            usedSections,
            documentType!,
        );
        return { usedSectionDetails, unusedSectionDetails };
    }, [documentType, unusedSections, usedSections]);
    const dispatch = useAppDispatch();
    const onAddSection = useCallback(
        (sectionConfigId: string) => {
            if (isAddingSection) return;
            setIsAddingSection(true);
            addSection(sectionConfigId, success => {
                if (success) {
                    dispatch(closeModal(ModalTypes.ADD_NEW_SECTION));
                } else {
                    dispatch(
                        addNotification({
                            messageType:
                                NotificationMessageType.RESUME_ADD_SECTION,
                            title: "Error adding section",
                            desc: "An error occurred while adding the section, please try again.",
                            type: NotificationType.ERROR,
                        }),
                    );
                }
                setIsAddingSection(false);
            });
        },
        [addSection, dispatch, isAddingSection],
    );
    const resumeStyles = useAppSelector(getActiveResumeStyles);

    const customSection = unusedSections.find(
        section => section.isCustomSection,
    );

    const sortedUnusedSections = useMemo(
        () =>
            unusedSections
                .filter(section => !section.isCustomSection)
                .sort((a, b) => {
                    if (
                        a.tags.includes("recommended") &&
                        !b.tags.includes("recommended")
                    )
                        return -1;
                    if (
                        !a.tags.includes("recommended") &&
                        b.tags.includes("recommended")
                    )
                        return 1;

                    // If both or neither are recommended, sort alphabetically by name
                    return a.name.localeCompare(b.name);
                })
                .concat(customSection ? [customSection] : []),
        [customSection, unusedSections],
    );

    const sortedUsedSections = useMemo(
        () =>
            usedSections
                .filter(
                    section =>
                        !section.isCustomSection && !section.isStaticHeader,
                )
                .sort((a, b) => {
                    if (
                        a.tags.includes("recommended") &&
                        !b.tags.includes("recommended")
                    )
                        return -1;
                    if (
                        !a.tags.includes("recommended") &&
                        b.tags.includes("recommended")
                    )
                        return 1;

                    // If both or neither are recommended, sort alphabetically by name
                    return a.name.localeCompare(b.name);
                })
                .concat(customSection ? [customSection] : []),
        [customSection, usedSections],
    );

    return (
        <Modal
            modalType={ModalTypes.ADD_NEW_SECTION}
            open={isOpen}
            title="Add section"
            width="7xl"
            afterClose={() => {
                setTabIndex(0);
            }}
        >
            <TabGroup
                className="overflow-hidden pb-10"
                defaultIndex={0}
                selectedIndex={tabIndex}
                onChange={setTabIndex}
            >
                <TabList className="mx-auto flex w-[500px] gap-3 rounded-lg border-[1px] border-neutral-300 p-1">
                    <TabButton>New Sections</TabButton>
                    <TabButton>Used Sections</TabButton>
                </TabList>
                <TabPanels className="mt-2 overflow-x-hidden overflow-y-scroll px-4 pb-4">
                    <TabPanel>
                        <ul
                            role="list"
                            className="section-preview my-4 grid grid-cols-2 gap-6 xl:grid-cols-3"
                        >
                            {sortedUnusedSections.map((section, index) => {
                                const sectionDetails = Object.values(
                                    unusedSectionDetails,
                                ).find(
                                    sectionDetail =>
                                        sectionDetail.sectionConfigId ===
                                        section.id,
                                );
                                if (!sectionDetails) return null;
                                const isRecommended =
                                    section.tags?.includes("recommended");
                                return (
                                    <li
                                        key={`${section.name}-${index}`}
                                        className="group relative col-span-1 h-52 cursor-pointer select-none divide-y divide-gray-200 overflow-hidden rounded-lg border-b-[16px] border-neutral-100 bg-neutral-100 pt-4 ring-2 ring-neutral-300 hover:border-neutral-200 hover:bg-neutral-200"
                                        onClick={(e: SyntheticEvent) => {
                                            e.preventDefault();
                                            onAddSection(section.id);
                                        }}
                                    >
                                        <NewSectionPreview
                                            sectionDetails={sectionDetails}
                                            sectionConfig={section}
                                            stylesConfig={resumeStyles}
                                        />
                                        <IconButton
                                            loading={isAddingSection}
                                            className="absolute bottom-0 right-4 h-10 w-10 opacity-0 transition-opacity group-hover:opacity-100"
                                        >
                                            <PlusSquareIcon size={24} />
                                        </IconButton>
                                        {isRecommended && (
                                            <Chip
                                                type="primary"
                                                className="absolute right-2 top-2"
                                            >
                                                <AppText variant="regular">
                                                    Recommended
                                                </AppText>
                                            </Chip>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    </TabPanel>
                    <TabPanel>
                        <ul
                            role="list"
                            className="section-preview my-4 grid grid-cols-2 gap-6 xl:grid-cols-3"
                        >
                            {sortedUsedSections.map((section, index) => {
                                const sectionDetails = Object.values(
                                    usedSectionDetails,
                                ).find(
                                    sectionDetail =>
                                        sectionDetail.sectionConfigId ===
                                        section.id,
                                );
                                if (!sectionDetails) return null;
                                return (
                                    <li
                                        key={`${section.name}-${index}`}
                                        className="group relative col-span-1 h-52 cursor-pointer select-none divide-y divide-gray-200 overflow-hidden rounded-lg border-b-[16px] border-neutral-100 bg-neutral-100 pt-4 ring-2 ring-neutral-300 hover:border-neutral-200 hover:bg-neutral-200"
                                        onClick={(e: SyntheticEvent) => {
                                            e.preventDefault();
                                            onAddSection(section.id);
                                        }}
                                    >
                                        <NewSectionPreview
                                            sectionDetails={sectionDetails}
                                            sectionConfig={section}
                                            stylesConfig={resumeStyles}
                                        />
                                        <IconButton className="absolute bottom-2 right-2 h-10 w-10 opacity-0 transition-opacity group-hover:opacity-100">
                                            <PlusSquareIcon size={24} />
                                        </IconButton>
                                    </li>
                                );
                            })}
                        </ul>
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </Modal>
    );
};

// Modals have to be exported as Default for lazy loading
export default AddNewSectionModal;
