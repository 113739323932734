import { getResumes } from "@/store/resume/selectors";
import { RootState } from "../store";
import { createSelector } from "@reduxjs/toolkit";
import { FREE_PLAN_MAX_RESUMES, WEEKLY_PLAN_MAX_RESUMES } from "@/constants/plans";
import { getIsAdminRole } from "@/utils/getIsRole";
import { USER_FLAGS } from "@/types/user";
import { formatUserName } from "@/utils/string";

export const getUserState = (state: RootState) => state.user;

export const getUserDetails = createSelector(
    getUserState,
    user => user.userDetails,
);

export const getUserId = createSelector(getUserDetails, user => user?.id);

export const getUserRole = createSelector(getUserDetails, user => user?.role);
export const getIsAdminUser = createSelector(getUserRole, role => getIsAdminRole(role));

export const getAccessToken = createSelector(
    getUserState,
    user => user.accessToken,
);

export const getRefreshToken = createSelector(
    getUserState,
    user => user.refreshToken,
);

export const getUserFullName = createSelector(
    getUserDetails,
    user => formatUserName(user),
);

export const getUserInitials = createSelector(
    getUserDetails,
    user => {
        if (!user) return "";
        const firstInitial = user.firstName ? user.firstName[0] : "";
        const lastInitial = user.lastName ? user.lastName[0] : "";
        return `${firstInitial}${lastInitial}`.toUpperCase();
    },
);

export const getStripeCustomerId = createSelector(
    getUserDetails,
    user => user?.stripeCustomerId,
);

export const getUserMarketingOptIn = createSelector(
    getUserDetails,
    user => user?.marketingOptIns,
);

export const getUserActivePlan = createSelector(
    getUserState,
    user => user.plan,
);

export const getUserHasActiveSubscription = createSelector(
    getUserActivePlan,
    plan => plan?.isSubscribed
);

export const getUserPlanEndingSoon = createSelector(
    getUserActivePlan,
    plan => plan?.details?.isCancelling && !!plan?.details?.daysBeforeEnd && plan.details.daysBeforeEnd <= 2,
);

export const getUserActivePlanName = createSelector(
    getUserActivePlan,
    plan => plan?.details?.name,
);

export const getUserPlanStatusIssue = createSelector(
    getUserActivePlan,
    plan => plan?.planStatus === "past_due" || plan?.planStatus === "incomplete_expired" || plan?.planStatus === "incomplete" || plan?.planStatus === "paused"
);

export const getUserPlanRequiresAttention = createSelector(
    getUserPlanStatusIssue,
    getUserPlanEndingSoon,
    (invalidStatus, endingSoon) => invalidStatus || endingSoon,
);

export const getUserHasUsedTrial = createSelector(
    getUserActivePlan,
    plan => plan?.hasUsedTrial,
);

export const getUserTrialDaysRemaining = createSelector(
    getUserActivePlan,
    plan => plan?.details?.trialDaysRemaining,
);

export const getUserReachedResumeLimit = createSelector(
    getIsAdminUser,
    getUserActivePlan,
    getResumes,
    (adminUser, plan, resumes) => {
        if (adminUser) return false;
        const currPlan = plan?.details?.interval;
        if (currPlan === "Monthly") return false;
        return resumes.length >= (plan?.isSubscribed && plan?.details?.interval === "Weekly" ? WEEKLY_PLAN_MAX_RESUMES : FREE_PLAN_MAX_RESUMES);
    }
);

export const getUserPreferences = createSelector(
    getUserDetails,
    user => user?.preferences,
);

export const getUserFlags = createSelector(
    getUserDetails,
    user => user?.flags,
);

export const getUserHasCompletedOnboarding = createSelector(
    getUserFlags,
    flags => flags?.[USER_FLAGS.HAS_SEEN_RESUME_BUILDER_TOUR],
);
