import clsx from "clsx";
import { ChangeEvent } from "react";

interface TextAreaProps
    extends Omit<
        React.TextareaHTMLAttributes<HTMLTextAreaElement>,
        "onChange"
    > {
    label?: string;
    name: string;
    id: string;
    defaultValue?: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    className?: string;
}

export const TextArea = ({
    label,
    name,
    id,
    defaultValue,
    onChange,
    className,
    ...props
}: TextAreaProps) => {
    return (
        <div>
            {label && (
                <label
                    htmlFor="comment"
                    className="block text-sm font-medium leading-6 text-neutral-900"
                >
                    {label}
                </label>
            )}
            <div className="mt-2">
                <textarea
                    rows={4}
                    name={name}
                    id={id}
                    className={clsx(
                        "block w-full rounded-lg border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                        className,
                    )}
                    defaultValue={defaultValue}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                        onChange(e)
                    }
                    {...props}
                />
            </div>
        </div>
    );
};
