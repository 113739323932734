import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Outlet, Navigate } from "react-router-dom";
import { getTokenHelper } from "@/helper/getTokens";
import { AppRoutes } from "@/types/routes";

export const AuthWrapper = () => {
    const token = getTokenHelper("accessToken");

    if (token) {
        // User is authenticated, redirect to dashboard
        return (
            <Navigate
                to={AppRoutes.Dashboard}
                replace
            />
        );
    }

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={import.meta.env.VITE_REACT_APP_RECAPTCHA_SITE_KEY}
            scriptProps={{
                async: true,
                defer: true,
                appendTo: "head",
            }}
        >
            <GoogleOAuthProvider
                clientId={import.meta.env.VITE_OAUTH_CLIENT_ID}
            >
                <Outlet />
            </GoogleOAuthProvider>
        </GoogleReCaptchaProvider>
    );
};
