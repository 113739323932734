import { CheckIcon } from "lucide-react";

interface PlanFeatureProps {
    children: React.ReactNode;
}

export const PlanFeature = ({ children }: PlanFeatureProps) => (
    <li className="flex items-center gap-x-2">
        <span className="flex h-6 w-6 items-center justify-center rounded-full bg-primary-700 text-white">
            <CheckIcon
                className="h-4 w-4"
                strokeWidth={3}
            />
        </span>
        {children}
    </li>
);
