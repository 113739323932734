/* eslint-disable @typescript-eslint/no-explicit-any */
export const transformToReactComponent = (
    templateString: string,
    templateVars: { [key: string]: any },
) => {
    if (!templateString) return templateString;
    const { numOpen, numClosed } = templateString.split("").reduce(
        (totals, currentChar) => {
            if (currentChar === "{") totals.numOpen++;
            if (currentChar === "}") totals.numClosed++;
            return totals;
        },
        { numOpen: 0, numClosed: 0 },
    );

    const hasEmptyVar = templateString.includes("${}");

    if (numOpen !== numClosed) {
        // If a jsx bracket is not closed, the below function will error and crash
        return `<div className="my-4 fixed">
            <span className="text-sm font-medium text-red-600">Syntax Error: Missing Bracket "${numClosed > numOpen ? "{" : "}"}"</span></div>`;
    } else if (hasEmptyVar) {
        return `<div className="my-4 fixed">
        <span className="text-sm font-medium text-red-600">Syntax Error: Empty Variables "\${}"</span></div>`;
    }

    return new Function("return `" + templateString + "`;").call(templateVars);
};

export const decodeHtml = (html: string) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
};

// reference https://gomakethings.com/how-to-add-a-new-item-to-an-object-at-a-specific-position-with-vanilla-js/

export const addToObject = (
    obj: { [key: string]: any },
    key: string,
    value: any,
    index: number,
) => {
    // Create a temp object and index variable
    const temp: { [key: string]: any } = {};
    let i = 0;

    // Loop through the original object
    for (const prop in obj) {
        if (obj.hasOwnProperty.call(obj, prop)) {
            // If the indexes match, add the new item
            if (i === index && key && value) {
                temp[key] = value;
            }

            // Add the current item in the loop to the temp obj
            temp[prop] = obj[prop];

            // Increase the count
            i++;
        }
    }

    // If no index, add to the end
    if (!index && key && value) {
        temp[key] = value;
    }

    return temp;
};
