import { CircleHelp } from "lucide-react";
import { useCallback } from "react";
import { ButtonColourStyles, ButtonVariant } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { BuilderTourStep } from "@/components/Onboarding/builder";
import { Tooltip } from "@/components/Tooltip/Tooltip";
import { useAppDispatch } from "@/hooks/types";
import { openModal } from "@/store/modal/slice";
import { ModalTypes } from "@/types/modal";

export const HelpButton = () => {
    const dispatch = useAppDispatch();

    const onOpenHelpGuideModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.HELP_GUIDE }));
    }, [dispatch]);

    return (
        <div className="group fixed bottom-[58px] right-4">
            <IconButton
                variant={ButtonVariant.SOLID}
                color={ButtonColourStyles.SOLID_PRIMARY}
                onClick={onOpenHelpGuideModal}
                size="10"
                className={BuilderTourStep.HELP_GUIDE}
            >
                <CircleHelp size={20} />
            </IconButton>
            <Tooltip
                text="Open Help Guide"
                positionClassName="-translate-x-[90%] -translate-y-[110%]"
            />
        </div>
    );
};
