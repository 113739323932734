import axios from "axios";
import { baseURL } from "./constants";
import { registerInterceptor } from "./interceptors/requestInterceptor";
import { setupAuthInterceptor } from './interceptors/responseInterceptor';

export const client = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
    },
});

registerInterceptor(client);
setupAuthInterceptor();

