import { NavLink as RouterNavLink, useParams } from "react-router-dom";
import { Logo } from "../Icons";
import { Settings } from "lucide-react";
import clsx from "clsx";
import { ButtonColourStyles, ButtonVariant } from "@/components/Button/Button";
import { ResumeName } from "@/components/ResumeName/ResumeName";
import SavingIndicator from "@/components/Navigation/SavingIndicator";
import { AdminNavigation } from "@/components/Navigation/AdminNavigation";
import { useAppSelector } from "@/hooks/types";
import { getIsAdminUser } from "@/store/user/selectors";
import { OneLogo } from "@/components/Icons/Icons";
import { getIsPreviewPage, getIsResumePage } from "@/utils/routes";
import { AppRoutes } from "@/types/routes";
import { getActiveResume } from "@/store/resume/selectors";
import { IconButton } from "@/components/Button/IconButton";
import { useCallback, useMemo, useState } from "react";
import { PlanStatusButton } from "@/components/Navigation/PlanStatusButton";
import { isFeatureEnabled } from "@/utils/featureFlags";
import { DownloadButton } from "@/components/Navigation/DownloadButton";
import { MobileMenu } from "@/components/Navigation/MobileMenu";
import { BurgerMenu } from "@/components/Navigation/BurgerMenu";
import { AccountSettingsButton } from "@/components/Navigation/AccountSettingsButton";
import { CreateMenu } from "@/components/Navigation/CreateMenu";
import { NavigationLink } from "@/components/Navigation/NavigationLink";

export const Navigation = () => {
    const { id } = useParams();
    const isAdminPage = window.location.pathname.includes("admin");
    const isAdmin = useAppSelector(getIsAdminUser);
    const isPreviewPage = getIsPreviewPage();
    const isResumePage = getIsResumePage();
    const activeResume = useAppSelector(getActiveResume);
    const isStripeEnabled = useMemo(
        () => isFeatureEnabled("VITE_FEATURE_STRIPE"),
        [],
    );
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const toggleMobileMenu = useCallback(() => {
        setIsMobileMenuOpen(prev => !prev);
    }, []);

    return (
        <>
            <div
                className={clsx(
                    "fixed top-0 z-20  bg-white",
                    (!id || isPreviewPage) &&
                        "border-b-[1px] border-neutral-300",
                    isPreviewPage ? "w-auto lg:w-full" : "w-full",
                )}
            >
                <div className="bg-neutral-white mb-0 rounded-xl px-3">
                    <div className="relative flex h-navigationHeight items-center justify-between">
                        {/* Desktop Navigation Header */}
                        <div className="hidden items-center gap-3 lg:flex">
                            <RouterNavLink
                                to={AppRoutes.Dashboard}
                                aria-label="Home"
                            >
                                {isResumePage ? (
                                    <OneLogo
                                        width={36}
                                        height={36}
                                    />
                                ) : (
                                    <Logo className="h-auto w-[150px]" />
                                )}
                            </RouterNavLink>
                            {isResumePage && <CreateMenu />}
                            <NavigationLink to={AppRoutes.Dashboard}>
                                Dashboard
                            </NavigationLink>
                            <NavigationLink to={`/${AppRoutes.Examples}`}>
                                Resume Examples
                            </NavigationLink>
                            {isResumePage && activeResume && (
                                <ResumeName
                                    resumeId={activeResume.id}
                                    resumeName={activeResume.name}
                                    isBuilderPage={true}
                                />
                            )}
                        </div>
                        <div className="hidden flex-row items-center gap-4 lg:flex">
                            {isResumePage && activeResume && (
                                <SavingIndicator />
                            )}
                            {isResumePage && <DownloadButton />}
                            {isStripeEnabled && <PlanStatusButton />}
                            <AccountSettingsButton />
                            {isAdmin && (
                                <IconButton
                                    variant={ButtonVariant.SOLID}
                                    color={ButtonColourStyles.SOLID_RED}
                                    to="/admin/stats"
                                >
                                    <Settings className="h-5 w-5" />
                                </IconButton>
                            )}
                        </div>
                        {/* Mobile Navigation Header */}
                        <div
                            className="flex w-full items-center justify-between gap-2 lg:hidden"
                            style={{
                                width: isPreviewPage
                                    ? "calc(100vw - 24px)"
                                    : "",
                            }}
                        >
                            <RouterNavLink
                                to={AppRoutes.Dashboard}
                                aria-label="Home"
                                className="hidden xs:block"
                            >
                                <Logo className="h-6 w-auto sm:h-4" />
                            </RouterNavLink>
                            <RouterNavLink
                                to={AppRoutes.Dashboard}
                                aria-label="Home"
                                className="block xs:hidden"
                            >
                                <OneLogo className="h-8 w-auto sm:h-4" />
                            </RouterNavLink>
                            <div className="flex items-center gap-2">
                                {isStripeEnabled && <PlanStatusButton />}
                                <BurgerMenu
                                    isOpen={isMobileMenuOpen}
                                    toggle={toggleMobileMenu}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {isAdminPage && <AdminNavigation />}
            </div>
            <MobileMenu
                isOpen={isMobileMenuOpen}
                onClose={toggleMobileMenu}
            />
            {/* Spacer for navigation */}
            <div
                className={clsx(
                    "navigation-spacer bg-neutral-100",
                    (!id || isAdminPage || isPreviewPage) &&
                        "h-navigationHeight",
                )}
            />
        </>
    );
};
