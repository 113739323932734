import { CLARITY_EVENT_NAMES, GA_EVENT_ACTIONS, GA_EVENT_CATEGORIES, META_CUSTOM_EVENT_ACTIONS, META_EVENT_ACTIONS } from '@/types/tracking';
import ReactGA from 'react-ga4';

const trackGAEvent = (
  category: GA_EVENT_CATEGORIES,
  action: GA_EVENT_ACTIONS,
  label?: string,
  value?: number,
): void => {
  ReactGA.event({
    category,
    action,
    label,
    value
  });
};

const trackMetaEvent = (event: META_EVENT_ACTIONS, params?: object) => {
  if (typeof window !== "undefined" && window.fbq) {
    window.fbq("track", event, params);
  }
};

function trackMetaCustomEvent(event: META_CUSTOM_EVENT_ACTIONS, params?: object) {
  if (typeof window !== "undefined" && window.fbq) {
    window.fbq("trackCustom", event, params);
  }
}

const trackClarityEvent = (eventName: CLARITY_EVENT_NAMES): void => {
  if (typeof window !== "undefined" && window.clarity) {
    window.clarity("event", eventName);
  }
};

const trackClarityIdentify = (email: string | null): void => {
  if (typeof window !== "undefined" && window.clarity) {
    window.clarity("identify", email);
  }
};

const trackClarityError = (scope: string): void => {
  if (typeof window !== "undefined" && window.clarity) {
    window.clarity("event", scope);
  }
};

export {
  trackGAEvent,
  trackMetaEvent,
  trackMetaCustomEvent,
  trackClarityEvent,
  trackClarityIdentify,
  trackClarityError,
};
