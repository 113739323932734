import { EmailHeading } from "@/email/components/EmailHeading";
import { EmailLink } from "@/email/components/EmailLink";
import { EmailText } from "@/email/components/EmailText";
import { EmailWrapper } from "@/email/components/EmailWrapper";
import { Section, Text } from "@react-email/components";

interface VerifyEmailProps {
    code?: string;
}

const VerifyEmail = ({ code = "1234" }: VerifyEmailProps) => (
    <EmailWrapper preview={`1Template Email Verification: ${code}`}>
        <Section>
            <EmailHeading>1Template verification code</EmailHeading>
            <EmailText>
                We want to make sure it&apos;s really you. Please enter the
                following verification code when prompted.
            </EmailText>
            <Text style={codeText}>{code}</Text>
            <EmailText>This code is valid for 15 minutes</EmailText>
            <EmailText>
                If you didn&apos;t request this verification, please ignore this
                email or contact us at{" "}
                <EmailLink href="mailto:hello@1template.io">
                    hello@1template.io
                </EmailLink>
                .
            </EmailText>
        </Section>
    </EmailWrapper>
);

const codeText = {
    fontSize: "36px",
    fontWeight: "700",
    color: "#3D61CC",
    margin: "24px 0",
    lineHeight: 1.2,
};

export default VerifyEmail;
