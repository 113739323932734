import { AdminResumeDetails } from "@/components/Admin/AdminResumeDetails";
import { Button } from "@/components/Button/Button";
import { IconButton } from "@/components/Button/IconButton";
import { Page } from "@/components/Page/Page";
import { duplicateResume } from "@/helper/duplicateResume";
import { useAppDispatch } from "@/hooks/types";
import { ReadOnlyResumeView } from "@/pages/Dashboard/ReadOnlyResumeView/ReadOnlyResumeView";
import { resumeToPages } from "@/pages/Examples/utils";
import { openModal } from "@/store/modal/slice";
import { Resume } from "@/types";
import { ModalTypes } from "@/types/modal";
import {
    GA_EVENT_ACTIONS,
    GA_EVENT_CATEGORIES,
    CLARITY_EVENT_NAMES,
} from "@/types/tracking";
import { trackGAEvent, trackClarityEvent } from "@/utils/tracking";
import clsx from "clsx";
import { Eye } from "lucide-react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ResumeExampleProps {
    resume: Resume;
}

export const ResumeExample = ({ resume }: ResumeExampleProps) => {
    const dispatch = useAppDispatch();
    const resumePages = resumeToPages(resume);
    const openResumePreview = useCallback(() => {
        dispatch(
            openModal({
                modalType: ModalTypes.RESUME_PREVIEW,
                props: { resume, resumePages, isResumeExample: true },
            }),
        );
        trackGAEvent(
            GA_EVENT_CATEGORIES.BUTTON,
            GA_EVENT_ACTIONS.CLICK,
            "Example resume previewed",
        );
    }, [dispatch, resume, resumePages]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const onUseExample = useCallback(() => {
        if (!resume) return;
        setLoading(true);
        duplicateResume({
            navigate,
            resume,
            isExampleResume: true,
        }).finally(() => {
            trackClarityEvent(CLARITY_EVENT_NAMES.EXAMPLE_RESUME_USED);
            setLoading(false);
        });
    }, [navigate, resume]);

    return (
        <div className="flex flex-col rounded-lg bg-white">
            <li className="resume-container group relative rounded-lg ring-2 ring-neutral-400">
                <AdminResumeDetails resume={resume} />
                <div className="resume-item group block cursor-pointer overflow-hidden rounded-lg ring-2 ring-neutral-400">
                    <Page
                        pageNumber={1}
                        overrideResumeStyles={resume.styles}
                    >
                        <ReadOnlyResumeView resume={resume} />
                    </Page>
                </div>
                <div
                    className={clsx(
                        "absolute inset-0 flex items-center justify-center transition-opacity duration-300 group-hover:opacity-100",
                        loading ? "opacity-100" : "opacity-0",
                    )}
                >
                    <Button
                        loading={loading}
                        disabled={loading}
                        className="z-10 hidden lg:block"
                        onClick={onUseExample}
                    >
                        Use this example
                    </Button>
                </div>
                <IconButton
                    className="absolute bottom-4 right-4 z-10"
                    onClick={openResumePreview}
                >
                    <Eye size={24} />
                </IconButton>
            </li>
        </div>
    );
};
