import { Alert } from "@/components/Alert/Alert";
import {
    ButtonVariant,
    ButtonColourStyles,
    Button,
} from "@/components/Button/Button";
import { Modal } from "@/components/Modal/Modal";
import { ModalFooter } from "@/components/Modal/ModalFooter";
import { AppText } from "@/components/Text/AppText";
import { ChangePassword } from "@/pages/Profile/ChangePassword";
import { TriangleAlertIcon } from "lucide-react";
import { useCallback, useState } from "react";
import userApi from "@/api/user";
import { useAppDispatch, useAppSelector } from "@/hooks/types";
import { getUserDetails } from "@/store/user/selectors";
import { useNavigate } from "react-router-dom";
import { logout } from "@/helper/logout";
import { sendDeleteRequestConfirmationEmail } from "@/email/send";
import { AppRoutes } from "@/types/routes";
import { ModalTypes } from "@/types/modal";
import { getIsModalOpen } from "@/store/modal/selectors";
import { openModal, closeModal } from "@/store/modal/slice";
import { useQueryClient } from "@tanstack/react-query";

export const SecurityAndPrivacy = () => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const userDetails = useAppSelector(getUserDetails);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const isModalOpen = useAppSelector(
        getIsModalOpen(ModalTypes.DELETE_ACCOUNT),
    );

    const openDeleteAccountModal = useCallback(() => {
        dispatch(openModal({ modalType: ModalTypes.DELETE_ACCOUNT }));
    }, [dispatch]);

    const handleCloseDeleteAccountModal = useCallback(() => {
        dispatch(closeModal(ModalTypes.DELETE_ACCOUNT));
    }, [dispatch]);

    const handleDeleteAccount = useCallback(async () => {
        setLoading(true);
        if (!userDetails?.id) {
            handleCloseDeleteAccountModal();
            setLoading(false);
            return;
        }

        userApi
            .deleteRequestApi(userDetails?.id)
            .then(async () => {
                // Send email to user confirming delete request
                await sendDeleteRequestConfirmationEmail(
                    userDetails?.email,
                    `${window.location.origin}/${AppRoutes.Login}`,
                );
                handleCloseDeleteAccountModal();
                logout(dispatch, navigate);
                queryClient.clear();
            })
            .catch(err => {
                console.error(err);
                setError(
                    "There was a problem deleting your account, please try again.",
                );
            })
            .finally(() => {
                handleCloseDeleteAccountModal();
                setLoading(false);
            });
    }, [
        dispatch,
        handleCloseDeleteAccountModal,
        navigate,
        userDetails?.email,
        userDetails?.id,
    ]);

    return (
        <>
            <div className="flex flex-col">
                <div className="mb-8 md:mb-0">
                    <AppText
                        variant="headings"
                        className="mb-8 hidden text-neutral-900 md:block"
                    >
                        Security & Privacy
                    </AppText>
                    <AppText
                        variant="subheadings"
                        className="mb-4 text-neutral-900"
                    >
                        Change password
                    </AppText>
                    <ChangePassword />
                </div>
                <div className="mb-8 md:mb-0">
                    <AppText
                        variant="subheadings"
                        className="mb-4 text-neutral-900"
                    >
                        Delete account
                    </AppText>
                    <AppText
                        variant="labelsbuttons"
                        className="mb-4 block text-neutral-700"
                    >
                        If you delete your 1Template account, you’ll lose access
                        to all your resumes.
                    </AppText>
                    <div className="float-end">
                        <Button
                            onClick={openDeleteAccountModal}
                            className="mb-4"
                            variant={ButtonVariant.SOLID}
                            color={ButtonColourStyles.SOLID_GREY}
                        >
                            Delete account
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                modalType={ModalTypes.DELETE_ACCOUNT}
                open={isModalOpen}
                title="Delete account"
                onClose={handleCloseDeleteAccountModal}
                width="lg"
                mobileFullScreen
                footer={
                    <ModalFooter
                        primaryActionText="Delete account and sign out"
                        primaryActionOnClick={handleDeleteAccount}
                        secondaryActionText="Cancel"
                        secondaryActionOnClick={handleCloseDeleteAccountModal}
                        primaryActionColor={ButtonColourStyles.SOLID_RED}
                        primaryActionLoading={loading}
                    />
                }
            >
                <div className="flex h-full flex-col justify-between gap-y-4 lg:h-auto lg:flex-col lg:justify-between">
                    <div className="flex flex-col gap-y-4">
                        <AppText
                            variant="subheadings"
                            className="mb-2 text-neutral-700"
                        >
                            Are you sure you want to delete your account?
                        </AppText>
                        <Alert
                            type="error"
                            icon={<TriangleAlertIcon />}
                            message={
                                <div>
                                    <AppText
                                        variant="contextheading"
                                        className="text-neutral-700"
                                    >
                                        Warning:
                                    </AppText>
                                    <AppText
                                        variant="regular"
                                        className="text-neutral-700"
                                    >
                                        Setting your account for deletion will
                                        prevent the renewal of any active
                                        subscriptions at the end of their
                                        current billing cycle.
                                    </AppText>
                                </div>
                            }
                        />
                        <AppText
                            variant="regular"
                            className="text-neutral-700"
                        >
                            You have a 30-day window to reconsider and
                            reactivate your account by simply logging back in.
                            After this period, your account and all associated
                            data will be permanently erased.
                        </AppText>
                        {error && (
                            <AppText
                                variant="regular"
                                className="mt-2 text-error-500"
                            >
                                {error}
                            </AppText>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};
