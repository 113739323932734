import React, { useState, useEffect, useMemo } from "react";
import { AppText } from "@/components/Text/AppText";
import { SiteRoutes } from "@/types/routes";
import {
    Button,
    ButtonColourStyles,
    ButtonVariant,
} from "@/components/Button/Button";
import { getUserCountryCode } from "@/i18n";

const CookieConsent: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    const countryCode = useMemo(() => getUserCountryCode(), []);

    useEffect(() => {
        const hasLocalStorageConsent =
            window.localStorage.getItem("gtm_consent_given");
        const hasCookieConsent = document.cookie
            .split(";")
            .some(cookie => cookie.trim().startsWith("gtm_consent_given="));

        if (!hasLocalStorageConsent && !hasCookieConsent) {
            setIsVisible(true);
        }

        if (hasLocalStorageConsent || hasCookieConsent) {
            window.clarity?.("consent");
        }
    }, []);

    const handleAccept = () => {
        window.gtag?.("consent", "update", {
            ad_storage: "granted",
            analytics_storage: "granted",
            functionality_storage: "granted",
            personalization_storage: "granted",
            security_storage: "granted",
        });

        window.clarity?.("consent");

        window.localStorage.setItem("gtm_consent_given", "true");
        setIsVisible(false);
    };

    const handleReject = () => {
        window.gtag?.("consent", "update", {
            ad_storage: "denied",
            analytics_storage: "denied",
            functionality_storage: "denied",
            personalization_storage: "denied",
            security_storage: "denied",
        });

        window.localStorage.setItem("gtm_consent_given", "false");
        setIsVisible(false);
    };

    if (!isVisible || !countryCode) return null;

    return (
        <div className="fixed bottom-4 left-4 z-[1000] mx-auto max-w-[300px] rounded-lg bg-white p-4 shadow-lg">
            <AppText
                variant="contextheading"
                className="mb-2"
            >
                Cookie Settings
            </AppText>
            <AppText
                variant="regular"
                className="my-2"
            >
                {countryCode === "US"
                    ? "We use cookies to enhance your experience, analyze site traffic, and deliver personalized content. Read our "
                    : "We use cookies to enhance your experience, analyse site traffic, and deliver personalised content. Read our "}
                <a
                    href={SiteRoutes.CookiePolicy}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-primary-700 hover:underline"
                >
                    Cookie Policy
                </a>
                .
            </AppText>
            <div className="mt-5 flex justify-end space-x-4">
                <Button
                    onClick={handleReject}
                    variant={ButtonVariant.SOLID}
                    color={ButtonColourStyles.SOLID_GREY}
                >
                    Reject
                </Button>
                <Button
                    onClick={handleAccept}
                    variant={ButtonVariant.SOLID}
                >
                    Accept
                </Button>
            </div>
        </div>
    );
};

export default CookieConsent;
